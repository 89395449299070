import Compareable from '../Comparable';

enum ServiceType  {
  'acd',
  'obm',
  null,
}

interface AccessUrls {
  agentServerUrl: string;
  apiUrl: string;
  packageRepositoryUrl: string;
  // name is expected when we have collection of AccessUrls what usually means blending on
  name?: ServiceType;
  api_endpoints: {
    agent_server: string;
    access_manager: string;
    package_repository: string;
  };
  api_hosts: string[],
  emsRestUrl: string;
}

export default class Urls extends Compareable {
  public _urlsMap: Map<ServiceType, UrlsEntry> = new Map<ServiceType, UrlsEntry>();

  public get api() {
    return this.getApiFor(null);
  }

  public get agentServer() {
    return this.getAgentServerFor(null);
  }

  public get accessManager() {
    return this.getAccessManagerFor(null);
  }

  public get packageRepository() {
    return this.getPackageRepositoryFor(null);
  }

  public getApiFor = (serviceName: ServiceType) => {
    return this._get(serviceName).api;
  }

  public getAgentServerFor = (serviceName: ServiceType) => {
    return this._get(serviceName).agentServer;
  }

  public getAccessManagerFor = (serviceName: ServiceType) => {
    return this._get(serviceName).accessManager;
  }

  public getPackageRepositoryFor = (serviceName: ServiceType) => {
    return this._get(serviceName).packageRepository;
  }

  public burnFor = (serviceName: ServiceType) => {
    return this._get(serviceName).burn();
  }

  private _get = (serviceName: ServiceType = null) => {
    return this._urlsMap.has(serviceName) ?
      this._urlsMap.get(serviceName) : this._urlsMap.get(null);
  }

  constructor(config) {
    super();

    if(Array.isArray(config.accessUrls)) {
      this.buildDistributedAccessUrls(config);
    } else {
      this.buildDefaultAccessUrls(config);
    }
  }

  private buildDefaultAccessUrls = (config: any) => {
    this._urlsMap.set(null, new UrlsEntry(config.accessUrls));
  }

  // parsing urls from each plugin's config if any
  private buildDistributedAccessUrls = (accessUrlsColl: AccessUrls[]) => {

    for (const accessUrls of accessUrlsColl) {
      this._urlsMap.set(accessUrls.name, new UrlsEntry(accessUrls));
    }
  }
}


class UrlsEntry extends Compareable {

  private _accessUrlsConfig: AccessUrls;
  private _api:string;
  private _agentServer:string;
  private _accessManager;
  private _packageRepository:string;
  private _hosts:string[];

  constructor(accessUrls: AccessUrls) {
    super();
    this._accessUrlsConfig = accessUrls;
    this._hosts = this._accessUrlsConfig.api_hosts || undefined;
    this.buildAccessUrls();
  }

  private buildAccessUrls = (host = this.getNextHost()) => {
    const isHostProvided = !!host;

    this._agentServer = isHostProvided ?
      'wss://' + host +  this._accessUrlsConfig.api_endpoints.agent_server :
      this._accessUrlsConfig.agentServerUrl;

    this._accessManager = isHostProvided ?
      'https://' + host + this._accessUrlsConfig.api_endpoints.access_manager :
      this._accessUrlsConfig.emsRestUrl || '';

    this._accessManager = this._accessManager.replace(/\/?$/, '/');

    // The package repository path should be relative to load content through Amazon Cloud
    this._packageRepository = isHostProvided ?
      this._accessUrlsConfig.api_endpoints.package_repository :
      this._accessUrlsConfig.packageRepositoryUrl?.replace(/\/*$/,'');

    this._api = isHostProvided ?
      'https://' + host : this._accessUrlsConfig.apiUrl || '';

    this._api = this._api.replace(/\/?$/, '/');

  };

  private getNextHost = () => {
    const host = this._hosts?.shift();
    return this._hosts?.push(host) && host;
  };

  public burn() {
    this.buildAccessUrls();
  }

  public get api() {
    return this._api;
  }

  public get agentServer() {
    return this._agentServer;
  }

  public get accessManager() {
    return this._accessManager;
  }

  public get packageRepository() {
    if (process.env.NODE_ENV === 'development') {
      return this.api.substring(0, this.api.length -1) + this._packageRepository;
    }
    return this._packageRepository;
  }
}