import { createProxy } from '../Proxy';
import MultichannelSdk from '../../api';
import { ScopedLogger } from '@core/logger';
import {
  observable,
  computed,
  action,
  reaction,
  runInAction,
} from 'mobx';
import CallGuide from './CallGuide';
import { EVENT_LIST } from '../constants';
import Compareable from '../../Comparable';
import { Destroyable } from '../../Destroyable';
import { QuestionType } from './Question';
import { QuestionOption } from './QuestionCheckBox';

/**
 * The Call Flow for handling Customer Call Guides
 */
export default class CallFlow extends Compareable implements Destroyable {
  _api: MultichannelSdk;
  _proxy;

  @observable _disabled = false;
  @observable _canReset = true;
  @observable _savedGuideline = null;

  private _subscription;
  private _events;
  private _apiEvents;
  private _allowedCallGuides;

  constructor(
    private _logger: ScopedLogger,
    private _messageHandler,
    private _sessionKey,
    private _userId,
  ) {
    super();
    this._proxy = createProxy(this);
    this._callGuides = observable({});
    this._activeCallGuides = observable({});
    this._allowedCallGuides = undefined;

    this._subscription = this._messageHandler.messagesByType(10092)
      .subscribe(res => this.handleIncomingCallGuide(res?.data));

    return this._proxy;
  }

  @computed get callGuides() {
    return this._callGuides;
  }

  @computed get activeCallGuides() {
    return this._activeCallGuides;
  }

  @computed get activeCallGuide() {
    return this._activeCallGuide;
  }

  @computed public get isStoreable(): boolean {
    return this.isRunning &&
      !this._disabled &&
      this.activeCallGuide?.isValid &&
      JSON.stringify(this.requestObject) !== this._savedGuideline;
  }

  set activeCallGuide(guide) {
    this._activeCallGuide = guide;
  }

  @action handleSave(): void {
    this._savedGuideline = JSON.stringify(this.requestObject);
  }

  /**
   * Get all Values from the Current Callguide
   * @see CallGuide.controlValues
   */
  @computed public get controlValues() {
    return this.activeCallGuide?.controlValues;
  }

  /**
   * Format the Callflow for the AS Request
   *
   * if the CallFlow has no active Callguide, undefined is returned.
   * @see http://doxygen1.a.office.4com.de/docs/protobuf_messages/10011_AsWrapUpReqMsg.proto.html
   */
  @computed public get requestObject() {
    if (!this.activeCallGuide) {
      return null;
    }

    return {
      callFlowId           : this.activeCallGuide.id + '',
      callFlowControlValues: this.controlValues.map(q => {
        switch (q.questionType) {
        case QuestionType.ComboBox:
        case QuestionType.RadioButton:
          return {
            id    : q.id + '',
            values: [{
              text : q.selected.label,
              id   : q.selected.id + '',
              value: q.selected.value,
            }]
          };
        case QuestionType.TextBox:
          return {
            id    : q.id + '',
            values: [{
              text : q.value,
              id   : '',
              value: '',
            }]
          };
        case QuestionType.CheckBox:
          return {
            id    : q.id + '',
            values: Object.values(q.selected).map((optionValue: QuestionOption) => {
              return {
                text : optionValue.label,
                id   : optionValue.id + '',
                value: optionValue.value,
              };
            })
          };
        default:
          return {
            id    : q.id + '',
            values: [{
              text : '',
              id   : q.id + '',
              value: q.value,
            }]
          };
        }
      }),
    };
  }

  /**
   * Validate the Callflow
   *
   * The Callflow is valid, if its active Callguide is valid and
   * the Callflow is not running.
   *
   */
  @computed get isValid(): boolean {
    const hasCallGuide = typeof this.activeCallGuide !== 'undefined';
    const isValidCallGuide = !hasCallGuide || this.activeCallGuide.isValid;
    return isValidCallGuide;
  }

  @computed get hasCallGuidesLoaded() {
    return this._hasCallGuidesLoaded;
  }

  @computed get isRunning(): boolean {
    return this._isRunning;
  }

  @observable _callGuides;
  @observable _activeCallGuides;
  @observable _activeCallGuide: CallGuide;
  @observable _callGuidesRequested: number = 0;
  @observable _hasCallGuidesLoaded: boolean = false;
  @observable _isRunning = false;

  public registerEvents(events) {
    this._apiEvents = events;
    this._events = events.on([EVENT_LIST.SDK_ME_CALL_FINISHED, EVENT_LIST.SDK_ME_CALL_DROP], action(() => {
      this._isRunning = false;
      this.reset();
      this._activeCallGuides = this._callGuides;
    }));
  }

  @action public disable() {
    this._disabled = true;
  }

  @action public enable() {
    this._disabled = false;
  }

  @computed public get canReset(): boolean {
    return this._canReset;
  }

  public set canReset(yesno: boolean) {
    this._canReset = yesno;
  }

  @computed public get isDisabled() {
    return this._disabled;
  }

  public allowCallGuides(
    callGuideList
  ): void {
    this._allowedCallGuides = callGuideList || undefined;
  }
  /**
   * Request the Callguides via Message Handler
   *
   * The Method returns a Promise, which resolves when
   * the Callguides has arrived. Otherwise it rejects after a given
   * Timeout
   *
   * @see http://doxygen1.a.office.4com.de/docs/protobuf_messages/10091_AsCallFlowGetReqMsg.proto.html
   * @param idList
   */
  public async requestCallGuides(
    callGuideList,
    timeout = 3000,
  ): Promise<boolean> {

    runInAction(() => {
      this._callGuidesRequested = 0;
      this._hasCallGuidesLoaded = false;
    });

    return new Promise((resolve, _reject) => {

      callGuideList.forEach(guide => {

        // Only request a new callguide when it is outdated or not available
        if ((this._allowedCallGuides === undefined || this._allowedCallGuides.includes(guide.id)) &&
          (!this._callGuides[guide.id] || this._callGuides[guide.id].isOutdated(guide.lastupdate))) {

          // Reset the callguide
          if (this._callGuides[guide.id]) {
            this._callGuides[guide.id] = undefined;
          }

          this._messageHandler.send(
            10091,
            '' + this._sessionKey,
            '' + this._userId,
            '' + guide.id,
          );

          this._callGuidesRequested++;

        }
      });

      // Only create the reaction when at least one callguide is requested
      if (this._callGuidesRequested > 0) {
        // We create a reaction to observe the Loading Process
        const disposeReaction = reaction(
          () => this._hasCallGuidesLoaded,
          (_hasCallGuidesLoaded, reaction) => {
            if (_hasCallGuidesLoaded) {
              // If the Loading process has been finished during the Timeout
              // the Promise resolves
              reaction.dispose();
              clearTimeout(_timeout);
              resolve(true);
            }
          },
          { fireImmediately: true }
        );

        // The reaction is disposed after given Timeout
        const _timeout = setTimeout(() => {
          disposeReaction();
          resolve(false);
        }, timeout);
        //Otherwise resolve the Promise
      } else {
        runInAction(() => {
          this._hasCallGuidesLoaded = true;
        });
        resolve(true);
      }
    });
  }

  /**
   * Handle incoming Call Guide Data
   * This is the handler for the incoming call guide info (10092) which was requested by 10091
   * @see {@link https://gitlab.4com.de/agentenserver/protobuf-messages/blob/master/proto3/11051_AsAcdCustomerConfigRespMsg.proto} for customerConfigResponse
   */
  @action handleIncomingCallGuide(data) {
    const callGuideId = parseInt(data[1][0], 10);
    const hasPages = typeof data[1][3].map !== 'undefined';
    if (typeof this._callGuides[callGuideId] === 'undefined' && hasPages) {
      const callGuide = new CallGuide(
        this,
        this._logger,
      );
      callGuide._init(data);
      this._callGuides[callGuideId] = callGuide;
    }

    this._callGuidesRequested--;

    // Ignore Guides with empty zero pages
    if (!hasPages) {
      this._logger.trace('Call Guide ' + callGuideId + ' has no Pages. Skipping.');
    }

    if (this._callGuidesRequested <= 0) {
      this._activeCallGuide = this._callGuides[Object.keys(this._callGuides)[0]];
      this._activeCallGuides = this._callGuides;
      this._hasCallGuidesLoaded = true;
    }
  }

  /**
   * Handle incoming Call Data
   * This is the handler for call data, containing call guide information.
   * @see {@link https://gitlab.4com.de/agentenserver/protobuf-messages/blob/master/proto3/11051_AsAcdCustomerConfigRespMsg.proto} for customerConfigResponse
   */
  @action async handleCallInfo(lvcallguide) {
    if (!lvcallguide || !lvcallguide.lvcallguidesList || !lvcallguide.lvcallguidesList.length) {
      return;
    }

    const list = lvcallguide.lvcallguidesList || [];

    if (await this.requestCallGuides(list)) {
      this.reset();
      this.enableCallGuides(
        list.map(guideMeta => guideMeta.id),
        lvcallguide.treeidautostart,
      );
    }
  }

  /**
   * Set Call Guides Active
   *
   * This Method was introduced with the OBM. It set the Callflow
   * into "Running" State
   * @param idList List of Call Guide ID's
   * @param autostartId
   */
  @action public enableCallGuides(idList, autostartId = undefined) {
    this._activeCallGuides = observable({});
    idList.forEach(id => {
      this._activeCallGuides[id] = this._callGuides[id];
    });

    this.activeCallGuide =
      typeof this._activeCallGuides[autostartId] !== 'undefined'
        ? this._activeCallGuides[autostartId]
        : this._activeCallGuides[idList[0]];

    this._isRunning = true;
  }

  @action reset() {
    this._savedGuideline = null;
    Object.entries(this._callGuides).forEach(data => {
      const guide = data[1];
      //@ts-ignore
      guide.reset();
    });
    this.enable();
  }

  public destroy() {
    this._subscription.unsubscribe();

    if (this._events) {
      this._apiEvents.off(this._events);
    }
  }
}
