import { fromEvent } from 'rxjs';
import AudioManager from '../Util/AudioManager';

const dtmfAutoTimeOut = 1500;
const dtmfToneVolume = 0.1;
const dtmfToneFrequencies = {
  '1': [697, 1209],
  '2': [697, 1336],
  '3': [697, 1477],
  '4': [770, 1209],
  '5': [770, 1336],
  '6': [770, 1477],
  '7': [852, 1209],
  '8': [852, 1336],
  '9': [852, 1477],
  '*': [941, 1209],
  '0': [941, 1336],
  '#': [941, 1477],
};

export default class DtmfTone {
  private _audioContext: any = null;
  private _frequency: Array<number>;
  private _isPlaying: boolean = false;

  private _oscillator0: any;
  private _oscillator1: any;
  private _gainNode: any;
  private _filter: any;
  private _playTimeOut: any;

  constructor(audioManager : AudioManager) {
    this._audioContext = audioManager.audioContext;
    this._isPlaying = false;
    this._frequency = dtmfToneFrequencies['0'];

    const keyUp = fromEvent(window, 'keyup');
    keyUp.subscribe(() => {
      this.stop();
    });
  }

  public play(_key: string, toneTimeout = null): void {
    if (!this._isPlaying && dtmfToneFrequencies[_key] && this._audioContext) {
      this._frequency = dtmfToneFrequencies[_key];

      this._oscillator0 = this._audioContext.createOscillator();
      this._oscillator1 = this._audioContext.createOscillator();
      this._oscillator0.frequency.value = this._frequency[0];
      this._oscillator1.frequency.value = this._frequency[1];

      this._gainNode = this._audioContext.createGain();
      this._gainNode.gain.value = dtmfToneVolume;

      this._filter = this._audioContext.createBiquadFilter();
      this._filter.type = 'lowpass';

      this._oscillator0.connect(this._gainNode);
      this._oscillator1.connect(this._gainNode);
      this._gainNode.connect(this._filter);
      this._filter.connect(this._audioContext.destination);

      this._oscillator0.start(0);
      this._oscillator1.start(0);

      if (dtmfAutoTimeOut > 0) {
        this._playTimeOut = setTimeout(() => this.stop(), toneTimeout > 0 ? toneTimeout : dtmfAutoTimeOut);
      }
      this._isPlaying = true;
    }
  }

  public stop(): void {
    if (this._isPlaying) {
      if (this._playTimeOut) {
        clearTimeout(this._playTimeOut);
      }
      this._oscillator0.stop(0);
      this._oscillator1.stop(0);
      this._isPlaying = false;
    }
  }
}
