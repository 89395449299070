import Question from './Question';
import {
  action,
  observable,
} from 'mobx';

import { ScopedLogger } from '@core/logger';

export default class QuestionTextBox extends Question {
  maxTextLength: number;
  maxVisibleLines: number;
  regularExpression: string;
  format: QuestionTextFormat;
  formatDescription: string;
  BackColor: number;
  ForeColor: number;
  Bold: boolean;
  Italic: boolean;
  Underlined: boolean;

  @observable value: string;

  @action public evaluate() {
    this.isEvaluated = true;
    this.hasError = typeof this.hasError === 'undefined'
      ? this.isMandatory && (
        (typeof this.value === 'string' && this.value.length === 0)
        || typeof this.value !== 'string')
      : this.hasError;
  }

  @action reset() {
    super.reset();
    this.value = undefined;
  }

  @action setValue(controlValue) {
    this.value = controlValue.lvvaluepairsList[0].value;
  }

  constructor(
    protected _logger: ScopedLogger,
    pageData,
  ) {
    super(
      _logger,
      pageData
    );
    const data = pageData[4];
    this.format = parseInt(data[0], 10);
    this.maxTextLength = parseInt(data[1], 10);
    this.maxVisibleLines = parseInt(data[2], 10);
    this.BackColor = parseInt(data[3], 10);
    this.ForeColor = parseInt(data[4], 10);
    this.Bold = data[5] === '1';
    this.Italic = data[6] === '1';
    this.Underlined = data[7] === '1';
    this.regularExpression = data[8];
    this.formatDescription = data[9];
    return this._proxy;
  }
}

/**
 * 0 - Validierung: Text
 * 1 - Validierung: Zahl
 * 2 - Validierung: Zahl (1 Nachkomma)
 * 3 - Validierung: Zahl (2 Nachkomma)
 * 4 - Validierung: Zahl (3 Nachkomma)
 * 5 - Validierung: Zeit (hh:mm)
 * 6 - Validierung: Zeit (hh:mm:ss)
 * 7 - Validierung: Datum (tt.mm.jjjj)
 * 8 - Validierung: Datum (tt.mm.jjjj hh:mm:ss)
 * 14 - Validierung: Datum (yyyy-mm-dd)
 * 15 - Validierung: Datum (yyyy-mm-dd hh:mm:ss)
 * 9 - Validierung: Währung Euro
 * 10 - Validierung: Währung Euro (Tausendertrennzeichen)
 * 11 - Validierung: Telefonnummer
 * 12 - Validierung: PLZ
 * 13 - Validierung: E-Mail-Adresse
 * 16 - Validierung: numerische Eingabe
 * 17 - Validierung: Regulärer Ausdruck
 */
export enum QuestionTextFormat {
  Text = 0,
  Number = 1,
  Decimal1 = 2,
  Decimal2 = 3,
  Decimal3 = 4,
  TimeHHMM = 5,
  TimeHHMMSS = 6,
  Date_DDMMYYYY = 7,
  Date_DDMMYYYY_HHMMSS = 8,
  Date_YYYYMMDD = 14,
  Date_YYYYMMDD_HHMMSS = 15,
  CurrencyEuro = 9,
  CurrencyEuroWithSeparator = 10,
  PhoneNumber = 11,
  PostCode = 12,
  EMail = 13,
  Numeric = 16,
  RegExp = 17,
}
