import Compareable from '../../Comparable';
import uuid from 'uuid';

export default class ScreenRecording extends Compareable {

  static DEFAULT_RECORDER_SETTINGS: Object = {};

  static DEFAULT_MEDIA_CONSTRAINTS: any = {
    video: true,
    audio: false
  };

  private recorder: MediaRecorder;
  private stream: MediaStream;
  private buffer: Array<any>;
  private config: any;
  private currentScreenSession: string | undefined;

  constructor() {
    super();
  }

  public initialize = (customerScreenRecordingConfig: any): void => {
    this.config = customerScreenRecordingConfig;
  }

  public start = async (recordingId: number): Promise<string | undefined> => {
    const recordingConfig = this.config?.recordings?.find(_id => recordingId);

    const updatedConstraints = {
      ...ScreenRecording.DEFAULT_MEDIA_CONSTRAINTS,
      video: { frameRate: recordingConfig?.frameRate }
    };

    this.currentScreenSession = uuid();

    try {
      //@ts-ignore
      this.stream = await navigator.mediaDevices.getDisplayMedia(updatedConstraints);
    } catch (e) { e => e; }

    if (this.stream) {
      this.startStreamRecording(this.stream);
      return this.currentScreenSession;
    } else {
      return undefined;
    }
  };

  public pause = (): void => {
    this.recorder.pause();
  };

  public resume = (): void => {
    this.recorder.resume();
  };

  public reset = (): void => {
    if (this.recorder) {
      this.stopCapture();
      this.recorder.ondataavailable = null;
      this.recorder.onstop = null;
      this.recorder = null;
    }
    if (this.stream) {
      this.stream = null;
    }
    this.currentScreenSession = undefined;
  };

  public finish = (): void => {
    if (this.recorder) {
      this.recorder.onstop = () => this.sendUploadRequest(new Blob(this.buffer, { type: 'video/webm' }));
    }
    this.stopCapture();
  };

  private stopCapture = ():void => {
    if(this.recorder) {
      this.recorder.stop();
    }
    if(this.stream) {
      let tracks = this.stream.getTracks();
      tracks.forEach(track => track.stop());
    }
    this.stream = null;
  };

  private startStreamRecording = (stream: MediaStream): void => {
    //this.stream.addEventListener('inactive', () => { handle 'stop sharing' button click });
    this.recorder = new MediaRecorder(stream, ScreenRecording.DEFAULT_RECORDER_SETTINGS);
    this.buffer = new Array();
    this.recorder.ondataavailable = (event: BlobEvent) => this.buffer.push(event.data);
    this.recorder.start();
  };

  private sendUploadRequest = (blob: Blob) => {
    const getAuthHeader = () => btoa(this.config.username + ':' + this.config.userpassword);

    const blobToFile = (blob, name) => new File([blob], name,
      { lastModified: new Date().getTime(), type: blob.type });

    const request = new XMLHttpRequest();
    let data = new FormData();
    const file = blobToFile(blob, `${this.currentScreenSession}.webm`);
    data.append('file', file);
    request.open('POST', this.config.url, true);
    request.setRequestHeader('Authorization', 'Basic ' + getAuthHeader());
    request.send(data);
  }
}