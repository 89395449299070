import SDKCallFlow from './CallFlow/CallFlow';

export function createCallFlow(
  _logger,
  messageHandler,
  sessionKey,
  userId,
) {
  return new SDKCallFlow(
    _logger,
    messageHandler,
    sessionKey,
    userId,
  );
}
