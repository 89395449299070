import * as openpgp from 'openpgp';

/**
 * CLass for encryption using GPG and RSA
 *
 * This class is used to encrypt the contents of
 * a Downloadable Logfile
 * @see https://4com.kanbanize.com/ctrl_board/5/cards/42531/details/
 */
class Encryption {
  init: boolean = false;
  /**
   * The Public Key for asymmetric encryption
   */
  publicKeyArmored = `-----BEGIN PGP PUBLIC KEY BLOCK-----

mQINBGAJjWkBEACmKwqR/utW43OlIjDU2HeZBIauZsL5Lm01/5dNoiZ2umFZtjzp
ZMun64x6s4/s+VEFYYOVGDFEWaZF5YBOJF3IIs1ByjGgIx5YqTcnpZbpFX9CdgkL
wOfeBmdReCAZM8rukpxeUx4QAkXeUOwubkEPmzQP3zAuIrYBTayLwMn8Dpwu14EY
LO7NG8vATLZfd9IMUxVG9epMzFnioMpOx3piO3fpyCJQyEOCj+W3BDs01Nto+dqH
fmjZ54EJImesk2sB75T/5Xr6793FTN1VGzYU25Vogs7d6seEZQVRZbNCE7ryWw9l
ua3td+XcyEV+16AVkKT0OLbt2f0MX2dzCNSmO8Pq6OKAHb3rqht9D2dELlHauu4a
5GTfwsNjPZa3CjizqHiiV/kQdF/VEC11HlwZetOtXSZqJKhdhg6jEhbrzw+XByXN
hO26RYvl9K6Bn+U3+cnygsoXYajjSLSkUjpAqSROpMCdwxOKL2ltFJlECq1GOZdB
ZMINxvuBgryEQA3RCwM3jhoASvfBO7vG5V2vQsPWy2d7XYdDCNRdswRy3G5GR7yG
P7l+x84l8j2nAeR/9eV5cYiqng1KN39kyoCA6xQ9XKcft3ArXs82bgkgUrPofKbS
gQCKSDHTwfU1/VtcR0T5EnU9N2XTah9ITnqPJwWGOjMV/HRfjkiAW7y9awARAQAB
tCVVRCBMb2dmaWxlIFJlYWRlciA8dWRsb2dmaWxlQDRjb20uZGU+iQJOBBMBCgA4
FiEEPE40pOLJqV7zrj4eerthWgFMDv4FAmAJjWkCGwMFCwkIBwIGFQoJCAsCBBYC
AwECHgECF4AACgkQerthWgFMDv6X/A//V+Efn4SniuULM3ofLp4eqg6syR61t/93
28T1SxX8NAVFa6J0VoQLTTFPD8FLZ+vXcUc9rOBeL/o3bCNHaOEPWxgzlmA5pWFG
kN+A32EjEK42rq9lw135npZKxCLcKbg2x2as1TWnKGJV+e7XrUZ0f5GfAl1+/zbQ
IA0HelvPkq1TXF3/a437a0Cx4QRJe4wSXIWphbkaNOMTCUjhmonmUY5oCttL2elW
UWqj2WUK62aKncD5TFBnvL8C4Ksb2GRzRvYBwQL8Vz3MIRmucTGe3oqG4HOvLw4q
u8km56Y/uq+lT7WnsGga+trq3dXpQXVjekkyK3OZWT+6mx0esSYvjKoS6Am/P10g
f2HvD1txWoZ9YhMenlODr/LtpvyEm4fpSvldVFkwENFVEQ0kRjOxO5fvImA2YP05
1GrGEVW1mWORlNsFlN2pXK0kjDn5mEXDxpb2yREJTeDYMP8vOeb4zLpTJnLKo1+h
tItVs4h9oC0lZ0t1ZEoJut0v8EzZCxBld+REBraGXwdXYUB1V4iJH0DDO36rmwwn
mfs62057TENxu3KSDtfKoTmgysqeWktWOrlxVls/w2aqoJLC8bOWkcUryofOawoO
K06mHqa8fW7saLT8MAqYj/ulCZUbC3jaRacheaEsLnRrUljzvvGA2vRAx4uXJ3lQ
d+H/9onK08e5Ag0EYAmNaQEQAM22LgzcYuML0c9eNLOPu8mFCxicQIktGwI/PVgo
CbQw3nd7OXBC6X5yTK2/s+hdt+DGB/2tAhShLE7QESU/ohiXzMgclurs1G9CzBQ9
aB1MiA9EqY1D1UupoJpiVPBJe9lPB3jr7ph1JB3qJFywWPOhUy7Cuzpnr20RKFxB
Hn6WvYhwCiXRwfB9RR58cbgLue2fYuCiSoRuY3Z6SZSVwLtS/d7Gd/5ldTDqClPf
yA1aO5nusL/Q+o6MRkDg/00eJZFrMX6WatSOryhDaIoraPPpBoAbEuVW2h/kYYcs
Fej4MtPfkcTw34N1gi92BnZGdzO87siTEFwrhd0VSC6glNBU3Cfi47NT4JcYQ9Tc
ziUAoAvnyMZOOSLGZSLPCOXr0h0TH16xhk+ZuUUaWeS8pWNW8eKmIz8nEdECNSka
G7zRQZ6rn2LXefNU2TTCMF9fYbBx+6qN7Trw7sTfiRslrXiw4l+18VRB3+EnTYPd
aaTa6LZBOOiIFISJ2sLCIH9q3wkDkp+mYogL1vOFgpAo0AuVZVaqeTF++gBGB5lL
MhHKiZ0nTYMQsSZve+mVRpK3K+NjgE1bc5s0sB4J9N03iH+lxbKe88b0Mae9C2Mk
Co0Ox5FVCN5Ll1VV+M2v3/lk2+WzMsKi553Fcg9G5FsrlTYbmcMYMa+d4JQtzO2M
69YDABEBAAGJAjYEGAEKACAWIQQ8TjSk4smpXvOuPh56u2FaAUwO/gUCYAmNaQIb
DAAKCRB6u2FaAUwO/nhiD/oDGyoHuS0u2p2GMj6qb6v6+lrE1BTl/uYR2V+OaUjc
U4PBkbm/hleV+mrJSsn8hmI9turtPuyos7CEh4YZZ+AeasTgTvt/GdyrhbZUMFpv
q6M5GGFTJbWK90cauZKDRVXCdgMR4W9T9/QpceQRo2xlIYCXTGyY/lY3xAHUzki2
z8QR2Q8X9hIdrVBJ9ACwVf3NOWbO1CZjTVC7z27XbgRFcVxv/lEl/zVS1E1nCz/x
0KQiZt9cjjengmb8RxyuFRt4ReBN2Uy13p5ba53pxt14UgdzOI/idbmd4CrZTNoJ
Tty09el4hAXkw5ypkjHgVobERmbQlqJiBvytnM7o4SMINUST/EeyfDJyhmb29IN+
vymwpoPGCu/UjxO1X6VQpHqw/KRHxDfMSO0VmH+Bx/yXkUHsYMFibcp0ioylfUiX
exbVYCjZRMPya1g+RuDeFvsiH5ITAOhZUOGgA+PtOUcSZMr7yKCIbh55HX61emii
iAfunL5tE4viPTkocSeCBuQl8NH53RF+yTIHsZNtMrNStymxllWl3/GD5oYFT9Qc
KvW+UEVuu87Qq7TGzHk1iknqM6pgSQpPDmrEB02DFf5yVBSOxL+OrF0/GWN7dZLk
/xkDshYczzwVZ1G8s6cWZ1Zuf3SvWWqpKkqjYoDWcdYi/nVumsXNmQ8+iNKFWsD6
fA==
=a++G
-----END PGP PUBLIC KEY BLOCK-----`;

  constructor() {
    try {
      openpgp.initWorker({ path: 'js/openpgp.worker.min.js' });
      this.init = true;
    } catch (err) {
      /* eslint-disable no-alert, no-console */
      console.error('Error while initializating Encryption Worker', err);
    }
  }

  public async encrypt(message: string) {
    const { data: encrypted } = await openpgp.encrypt({
      message   : openpgp.message.fromText(message),                 // input as Message object
      publicKeys: (await openpgp.key.readArmored([this.publicKeyArmored])).keys, // for encryption
    });

    return encrypted;
  }
}

export default Encryption;