
import { observable } from 'mobx';
import Compareable from '../../Comparable';
import { VoIPResult, VoIPTest, VoIPTestFunction } from './SelfCheck';

export default class AudioInputTest extends Compareable implements VoIPTest {
    title: string;
    results?: VoIPResult[];
    testResult?: VoIPResult;
    testFn: VoIPTestFunction;
    @observable running?: boolean = false;
    showAnimation: boolean = true;

    public constructor(title, fn) {
      super();
      this.title = title;
      this.testFn = fn;
    }
}

