

import 'core-js';
import 'regenerator-runtime/runtime';
import moment from 'moment';
import Auth from './api/Auth/Auth';
import AudioManager from './api/Util/AudioManager';
import Events from '@core/events';
import type Groups from '../../acd/src/api/Groups';
import Language from './api/Language/Language';
import {MessageFactoryProducer} from '@core/messages';
import {MessageHandler} from '@core/messages';
import EmsRestService from './api/Network/EmsRestService';
import RestService from './api/Network/RestService';
import Connection from './api/Network/Connection';
import type OnlineMonitor from '../../acd/src/api/OnlineMonitor';
import Plugins from '@core/plugins';
import { createProxy } from './api/Proxy';
import Storage from '@core/storage';
import Actions from '@core/actions';
import ScreenRecording from './api/Util/ScreenRecording';
import VoIP from './api/VoIP/VoIP';
import { Services } from './api/Services';
import type WorkItems from '@multichannel/acd/src/api/WorkItems';
import type ContactHistory from '@multichannel/acd/src/api/ContactHistory';
import type TextModules from '@multichannel/acd/src/api/TextModules';
import type Coaching from '@multichannel/acd/src/api/Coaching';

import uuid4 from 'uuid';
import JSON5 from 'json5';
import Urls from './api/Urls';
import Encryption from './api/Encryption';
import CONSTANTS, { EVENT_LIST, EVENT_LIST_DEPRECATED } from './api/constants';
export { EVENT_LIST, EVENT_LIST_DEPRECATED } from './api/constants';

import {
  DownLoadableFileLogger,
  LOG_LEVEL_DEBUG,
  LOG_LEVEL_ERROR,
  LOG_LEVEL_INFO,
  LOG_LEVEL_TRACE,
  LOG_LEVEL_WARN,
  LogService,
  ScopedLogger,
} from '@core/logger';
import Compareable from './Comparable';
import { from, fromEvent } from 'rxjs';
import { computed, observable, action, runInAction } from 'mobx';
import { configure } from 'mobx';
import * as Factory from './api/Factory';
import { mergeMap } from 'rxjs/operators';


configure({
  enforceActions: process.env.NODE_ENV === 'development' ? 'always' : 'never',
});

const DEFAULT_CONFIG = {
  callLogging: true,
  language   : null,
  useSecurity: true,
  uuid       : null,
};

const MODULES = {
  auth                  : Auth,
  emsRestService        : EmsRestService,
  language              : Language,
  messageFactoryProducer: MessageFactoryProducer,
  messageHandler        : MessageHandler,
  //storage               : Storage,
  screenRecording       : ScreenRecording,
  //plugins               : Plugins,
  voip                  : VoIP,
  audio                 : AudioManager,
};

/**
 * An api to multiple endpoints of the 4Com ecosystem providing
 * methods to interact with the platform as a user.
 *
 * @property {Auth} auth - Authentication module
 * @property {Calls} calls - Calls module
 * @property {CallHistory} callHistory - CallHistory module
 * @property {Groups} groups - Groups module
 * @property {Language} language - Language module
 * @property {Socket} socket - Socket module
 * @property {MessageHandler} messageHandler - MessageHandler module
 * @property {OnlineMonitor} onlineMonitor - OnlineMonitor module
 * @property {VoIP} voip - VoIP module
 * @property {LogService} logger - LogService module
 * @property {Events} events - Events system
 * @property {Plugins} plugins - Plugin manager
 * @property {Actions} actions
 * @property {ContactHistory} contactHistory
 * @property {ScreenRecording} screenRecording
 */
export interface Logger {
  debug;
  error;
  trace;
  traceCall;
  traceConstruct;
}

export type Debug = (namespace: string) => Logger

interface Config {
  callLogging: boolean;
  customerId: number;
  language: string;
  useSecurity: boolean;
}

interface BuildInfo {
  buildHash: string;
  buildTime: string;
  buildVersion: string;
}

interface AccessUrls {
  agentServerUrl: string;
  apiUrl: string;
  packageRepositoryUrl: string;
}

interface CustomerConfig {
  clientVersion?: string;
  customerId: string;
  customerName: string;
}

interface Licence {
  voip: boolean;
}

interface VoIPSettings {
  peerConnectionOptions: any;
}
interface Sdk {
  mail?: {
    keepMailOpenWhileOutboundCall?: boolean
  }
  flags: Flags;
  voip: VoIPSettings;
}

interface Flags {
  developmentMode: boolean;
}

interface PluginConfig {
  plugin: {
    name: string;
    url: string;
  };
}

interface Plugin {
  name: string;
  config?: PluginConfig;
}

interface PlatformConfig {
  emsRestUrl: string;
  accessUrls: AccessUrls;
  customer: CustomerConfig;
  licence: Licence;
  sdk: Sdk;
  plugins: Plugin[];
  // PlatformConfig can have any number of other properties
  [propName: string]: any;
}

export default class MultichannelSdk extends Compareable {
  /**
   *
   * @returns {string}
   */
  public get acdInstance(): string {
    return this._acdInstance;
  }

  /**
   *
   * @returns {string}
   */
  public get appName(): string {
    return this._config?.app?.name || '';
  }

  public get buildInfo(): BuildInfo {
    const buildTime = process.env.BUILD_TIMESTAMP;
    const buildHash = process.env.BUILD_REVISION;
    const buildVersion = process.env.BUILD_VERSION;
    return {
      buildHash,
      buildTime,
      buildVersion,
    };
  }

  /**
   * The configuration set upon initialization.
   *
   * @type {{}}
   */
  public get config(): PlatformConfig {
    return this._config;
  }

  /**
   *
   * @returns {string}
   */
  public get configKey(): string {
    return this._configKey;
  }

  public get callHistory() {
    return this._callHistory;
  }

  public set callHistory(callHistory) {
    this._callHistory = callHistory;
  }

  // for backwards compatibility
  @computed public get customer() {
    this._logger.trace('Single customer getter might soon be deprected, please use new accessor approach.');
    return this.services.activeCustomer;
  }

  @computed public get groups() {
    return this._groups;
  }

  public set groups(groups) {
    runInAction(() => {
      this._groups = groups;
    });
  }

  @computed public get onlineMonitor() {
    return this._onlineMonitor;
  }

  public set onlineMonitor(onlineMonitor) {
    runInAction(() => {
      this._onlineMonitor = onlineMonitor;
    });
  }

  public get calls() {
    return this._calls;
  }

  public set calls(calls) {
    runInAction(() => {
      this._calls = calls;
    });
  }

  public get contactHistory() {
    return this._contactHistory;
  }

  public set contactHistory(contactHistory) {
    runInAction(() => {
      this._contactHistory = contactHistory;
    });
  }

  @computed public get textModules() {
    return this._textModules;
  }

  public set textModules(textModules) {
    runInAction(() => {
      this._textModules = textModules;
    });
  }
  @computed public get workItems() {
    return this._workItems;
  }

  public set workItems(workItems) {
    runInAction(() => {
      this._workItems = workItems;
    });
  }


  @computed public get currentCall(): any | undefined {
    return this.auth.me ? this.auth.me.currentCall : undefined;
  }

  public get title(): string {
    //@ts-ignore
    const version = `${this.version} ${this.config && this.config.voipLicence ? '(VoIP)' : ''}`;
    return `Professional Client ${version}`;
  }

  /**
   * Get the current list of all registered tabs
   */
  public get instanceList(): string[] {
    return this.storage.get('sdkInstances', 'instances') || [];
  }
  public set instanceList(list: string[]) {
    this.storage.set('sdkInstances', list, 'instances');
  }

  /**
   * Get the current position of the tab instance
   */
  public get instancePosition(): number {
    const findInstance = this.instanceList.indexOf(this._instanceId);

    return findInstance < 0 ? 0 : findInstance;
  }

  get instanceId(): string {
    return this._instanceId || '';
  }

  /***********************
   * convenience getters *
   ***********************/
  @computed public get currentUser(): any | null {
    return this.auth ? this.auth.me : null;
  }

  @computed public get currentWorkItem() {
    return this.auth?.me ? this.auth.me.currentWorkItem : undefined;
  }

  public get debugMode(): boolean {
    return this._debugMode;
  }

  public set debugMode(debugMode) {
    this._debugMode = debugMode;

    if (debugMode) {
      Events.trigger(EVENT_LIST.SDK_DEBUG_MODE_ENABLED);
    } else {
      Events.trigger(EVENT_LIST.SDK_DEBUG_MODE_DISABLED);
    }
  }

  public async fanOut(delay: number = 250, maxDelay: number = 10000): Promise<boolean> {
    let waitTime = this.instancePosition * delay;
    if (waitTime > maxDelay) {
      waitTime = maxDelay;
    }
    this._logger.trace('fanOut with delay', { delay: waitTime });
    return new Promise(resolve => {
      window.setTimeout(() => resolve(true), waitTime);
    });
  }

  /**
   *
   * @returns {*}
   * @private
   */
  public static async fetchConfig(): Promise<any> {
    const path = window.location.pathname;

    let origin =
      window.location.origin ||
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '');
    let match = /clients\/v\d*\/acds\/([\w]*)\/configs\/([\da-f-]*)\/webclients\/?/g.exec(path);
    let url: string = null;
    let fetch = null;
    try {
      (origin =
        window.location.origin ||
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '')),
      (match = /clients\/v\d*\/acds\/([\w]*)\/configs\/([\da-f-]*)\/webclients\/?/g.exec(
        window.location.href,
      ));

      if (match) {
        url = `${origin}/clients/v161212/acds/${match[1]}/configs/${match[2]}/`;
      } else if (window.multichannelJavaScriptSDKConfigUrl) {
        url = window.multichannelJavaScriptSDKConfigUrl;
      } else {
        const parameter = /configURL=([^&]+)/.exec(window.location.href);
        url = parameter ? decodeURIComponent(parameter[1]) : 'noConfigURL';
      }
      fetch = await new RestService()
        .fetchAndRead(url).then(response => ({ url, ...JSON5.parse(response) }));
    } catch (e) {
      // Try to get the location of the current script.
      let baseUrl = '';
      const stackMatches = new Error().stack.match(/((http[s]?:\/\/.+\/)([^/]+\.js)):/);
      if (stackMatches && stackMatches.length) {
        baseUrl = stackMatches[0].substring(0, stackMatches[0].lastIndexOf('/') + 1);
      }

      // Use local configuration file during development.
      url = baseUrl + 'platform-config.json';
      fetch = await new RestService()
        .fetchAndRead(url).then(response => ({ url, ...JSON5.parse(response) }));
    }

    return fetch;
  }

  /**
   * The levels usable by the logger.
   *
   * @type {{}}
   */
  public get log_levels() {
    return {
      DEBUG: LOG_LEVEL_DEBUG,
      ERROR: LOG_LEVEL_ERROR,
      INFO : LOG_LEVEL_INFO,
      TRACE: LOG_LEVEL_TRACE,
      WARN : LOG_LEVEL_WARN,
    };
  }

  /**
   * No part should use api.logger directly, instead use api.debug() with a correct scope.
   *
   * @deprecated
   * @returns {{error, debug, trace, traceCall, traceConstruct, traceParams, log}}
   */
  public get logger(): ScopedLogger {
    return this.logService.scoped('deprecated_default_scope');
  }

  /**
   * This should only be used to trigger logfile downloads etc.
   * Use the .debug() method with a scope definition for actual logging.
   *
   * @returns {LogService}
   */
  public get logService(): LogService {
    return this._logService;
  }

  /**
   * Encryption GPG
   * @see https://4com.kanbanize.com/ctrl_board/5/cards/42531/details/
   */
  public get encryption(): Encryption {
    return this._encryption;
  }

  /**
   *
   * @param {{}} response
   * @public
   */
  public static parseConfigResponse(response) {
    const config = response;

    let customerId;

    if (config.customer.customerId) {
      customerId = '' + config.customer.customerId;
    } else {
      customerId = '' + config.customer.CustomerId;
    }

    config.customerId = customerId;
    config.clientVersion = config.customer.clientVersion;
    config.voipLicence = config.licence && !!config.licence.voip;
    config.customerName = config.customer.customerName;

    config.urls = new Urls(config);

    config.flags = config.sdk && config.sdk.flags ? config.sdk.flags : {};
    return config;
  }

  /**
   * Get window performance information
   */
  public get performanceInfo(): { info: string; entries: PerformanceEntry[] } {
    const performance = { info: 'not available', entries: [] };
    const validTypes = ['frame', 'navigation', 'resource', 'mark', 'measure'];
    if (window.performance) {
      const toJSON = (entry): any => {
        return entry.toJSON ? entry.toJSON() : (entry as Compareable).toJSON();
      };
      let entries = [];
      try {
        validTypes.forEach(type => {
          entries = [...entries, ...window.performance.getEntriesByType(type).map(entry => toJSON(entry))];
        });
      } catch (e) {
        //Nothing todo here
      }
      try {
        performance.info = toJSON(window.performance);
      } catch (e) {
        //Nothing todo here
      }

      performance.entries = entries;
    }
    return performance;
  }

  /**
   * Session key for communication with the Agentserver.
   *
   */
  public get sessionKey(): string {
    return this.storage.get('session-key', 'sdk');
  }

  public set sessionKey(sessionKey: string) {
    this.storage.set('session-key', sessionKey, 'sdk');
    this._logger.trace('Session key set', sessionKey);
  }

  /**********
   * getter *
   **********/

  /**
   * The current version of the API.
   *
   * @type {String}
   */
  public get version(): string {
    return CONSTANTS.VERSION;
  }

  public audio: AudioManager;
  public connection: Connection;
  public _contactHistory: ContactHistory;
  public emsRestService: EmsRestService;
  @observable public voip: VoIP;
  @observable public _groups: Groups;
  public language: Language;
  public messageFactoryProducer: MessageFactoryProducer;
  public messageHandler: MessageHandler;
  public _onlineMonitor: OnlineMonitor;
  public plugins = Plugins;
  public serverTimeDelta: number;
  //public storage: Storage;
  public screenRecording: ScreenRecording;
  public _textModules: TextModules;
  public coaching: Coaching;
  @observable public auth: Auth;
  @observable public services: Services;
  @observable public initialized: boolean;
  @observable public pwaInstaller: any;
  private _calls: any;
  private _callHistory: any;
  private _workItems: WorkItems;
  public isClientBasic: boolean;

  /**
   *
   * @param {{}} [config={}]
   * @param {Number} config.customerId
   * @param {Boolean} [config.useSecurity=true]
   * @param {Boolean} [config.callLogging=true]
   * @param {String} config.language
   */
  constructor(config: Config | null = null) {
    super();

    const match = /clients\/v\d*\/acds\/([\w]*)\/configs\/([\da-f-]*)\/webclients\/?/g.exec(
      window.location.href,
    );

    if (match) {
      this._acdInstance = match[1];

      this._configKey = match[2];
    }

    if (this._configKey === undefined) {
      this._configKey = 'local';
    }

    this._proxy = createProxy(this);

    this._config = Object.assign(
      DEFAULT_CONFIG,
      config ? MultichannelSdk.parseConfigResponse(config) : {},
    );

    if (this._acdInstance === undefined) {
      const agentServerUrl = this._config ? this._config.urls.getAgentServerFor(this.services?.activeService) : '';
      const reg = /clientusercontrol\/([\w]*)\/?/g.exec(agentServerUrl) || [];
      this._acdInstance = reg && reg.length && reg.length >= 2 ? reg[1] : undefined;
    }

    Storage.setApiInstance(this._proxy);
    Actions.setApiInstance(this._proxy);
    Plugins.setApiInstance(this._proxy);

    this._logService = new LogService(this._proxy);
    this._logService.registerFunctionToCatchUncaughtJSErrors();
    this._logService.setLogger(new DownLoadableFileLogger('MultichannelSDKDownloadableFileLogger'));
    this._logService.restore();

    this.connection = new Connection(this);
    this._logger = this.debug('api');


    //@ts-ignore
    Events.setLogger(this.debug('api.events'), config?.flags?.enableVerboseLogging);
    Actions.setLogger(this.debug('api.actions'));
    Plugins.setLogger(this.debug('api.plugins'));

    this._logger.trace('Config object', config);
    this._encryption = new Encryption();

    /**
     * The time difference between the server and the client in milliseconds.
     *
     * @type {null|number}
     */
    this.serverTimeDelta = null;


    runInAction(() => {
      this.initialized = false;
      this.coaching = null;
      this.services = new Services();
    });


    this._logService.init();
    this.messageHandler = new MessageHandler(this._proxy);

    this._logService.registerActions();

    Actions.register('sdk.pwa.install', shouldInstall => {
      if (this.pwaInstaller && shouldInstall) {
        this.pwaInstaller.prompt();
      }
      this.storage.set('hidden', true, 'sdkpwainstaller');
    });

    Actions.register('sdk.urls.burn', () => {
      this._config?.urls.burn();

      try {
        this.auth.logout();
      } catch (e) {
        this._logger.error('Cannot Teardown nicely! Try to burn socket and reconnect', e);
        this.messageHandler.reconnect();
      }
    });

    //connect to Server
    try {
      if (config) {
        this.initConnection();
      }
    } catch (failure) {
      Events.trigger([EVENT_LIST.SDK_CONNECT_ERROR, 'error:could not load config'], failure);
    }
    //@ts-ignore
    if (config && config.flags && config.flags.developmentMode) {
      this.debugMode = true;
    }

    if (document) {
      //@ts-ignore
      document.title = this.title;
    }

    const tsDocument = document;
    let visibilityChange;

    // Opera 12.10 and Firefox 18 and later support
    if (typeof tsDocument.hidden !== 'undefined') {
      visibilityChange = 'visibilitychange';
      //@ts-ignore
    } else if (typeof tsDocument.msHidden !== 'undefined') {
      visibilityChange = 'msvisibilitychange';
      //@ts-ignore
    } else if (typeof tsDocument.webkitHidden !== 'undefined') {
      visibilityChange = 'webkitvisibilitychange';
    }
    // Generate an unique sdk instance id
    this._instanceId = uuid4();
    const instanceList = this.instanceList;
    if (document.visibilityState === 'visible') {
      instanceList.unshift(this._instanceId);
    } else {
      instanceList.push(this._instanceId);
    }
    this.instanceList = instanceList;
    this._proxy.audio.storeCapabilities();


    const events = ['mouseenter', visibilityChange];
    from(events).pipe(
      mergeMap(event => fromEvent(document, event)),
    ).subscribe(() => {
      if (document.visibilityState === 'visible') {
        let instanceList = this.instanceList;
        instanceList = instanceList.filter(instance => instance !== this._instanceId);
        instanceList.unshift(this._instanceId);
        this.instanceList = instanceList;
      }
    });

    fromEvent(window, 'unload').subscribe(() => {
      this.instanceList = this.instanceList.filter(instance => instance !== this._instanceId);
      this._proxy.audio.clearCapabilities();
    });

    fromEvent(window, 'beforeinstallprompt').subscribe((event) => {
      event.preventDefault();
      this._logger.trace('Pwa Installer inited');
      runInAction(() => {
        this.pwaInstaller = event;
      });
    });

    fromEvent(window, 'appinstalled').subscribe(() => {
      this._logger.trace('Pwa installed');
      runInAction(() => {
        this.pwaInstaller = undefined;
      });
    });

    const getMessage = this.messageHandler.messagesByType;
    getMessage(11057).subscribe(res => Events.trigger(
      this.EVENT_LIST.SDK_USER_STATISTIC_RAW, res?.obj
    ));
    getMessage(190119).subscribe(res => Events.trigger(
      this.EVENT_LIST.SDK_MESSAGE_ACD_TRANSIT_ACTION_SWITCH_WORKSPACE,
      res?.obj?.workspaceuuid
    ));

    return this._proxy as any;
  }

  private _acdInstance: string;
  private _config: PlatformConfig;
  private _configKey: string;
  private _debugMode: boolean = false;

  private _instanceId: string;
  private _logger: Logger;
  private _logService: LogService;
  private _encryption: Encryption;
  private _proxy: MultichannelSdk;

  get pwaInstallerAvailable() {
    return !!this.pwaInstaller && !this.storage.get('hidden', 'sdkpwainstaller');
  }

  /**
   * Allows to store inter-session data in browser.
   *
   * @deprecated Please consider to directly import the Storage class
   * by using
   *
   * `import Storage from '@core/storage'`
   *
   * instead.
   */
  public get storage() {
    return Storage;
  }

  /**
   * Allows for specific parts of the busines logic to be registered as a callable action.
   *
   * @deprecated Please consider to directly import the Actions class
   * by using
   *
   * `import Actions from '@core/actions'`
   *
   * instead.
   */
  public get actions() {
    return Actions;
  }

  @action public __get(propertyName) {
    if (MODULES[propertyName]) {
      this[propertyName] = new MODULES[propertyName](this._proxy);
    }

    return this[propertyName];
  }

  /**
   * Inits everything to work correctly
   *
   */
  public connect() {
    // this._logger.traceCall("connect");
    MultichannelSdk.fetchConfig()
      .then(config => MultichannelSdk.parseConfigResponse(config))
      .then(config => {
        this._config = Object.assign(this._config, config);
        this.initConnection();
      })
      .catch(failure => {
        Events.trigger([EVENT_LIST.SDK_CONNECT_ERROR, 'error:could not load config'], failure);
      });
  }

  public updatePluginConfig(pluginName: string, pluginConfig: any) {
    if (this._config.plugins) {
      this._config.plugins = this._config.plugins.map(plugin => {
        if (plugin.name.includes(pluginName)) {
          plugin.config = pluginConfig;
        } else if (plugin.name === 'multichannel/development-plugin-container') {
          if (plugin.config?.plugin?.name.includes(pluginName)) {
            //@ts-ignore
            plugin.config.plugin.config = pluginConfig;
          }
        }
        return plugin;
      });
    }
  }

  /**
   *
   * @param {string|null} [scopeName]
   * @returns {{error, debug, trace, traceCall, traceConstruct, traceParams, log}}
   */
  public debug(scopeName: string = ''): ScopedLogger {
    return this.logService.scoped(scopeName);
  }

  /**
   *
   * @public
   */
  public async initConnection(): Promise<void> {
    // Ugly way to start off the plugin manager.
    await Plugins.init();
    this.messageHandler.reconnect();
  }

  /**
   * Resets the internal state of the api.
   */
  @action
  public reset() {
    this._logger.traceCall('reset');
    if (this.voip && this.voip.connected) {
      this.voip.reset();
    }

    this.sessionKey = null;
    this.serverTimeDelta = null;

    if (this.groups) {
      this.groups.didSelectGroup = false;
    }
  }

  /**
       * Set the servertime Delta
       *
       * @param {number} timestamp - The current server time in seconds since epoch
       */
  public calculateServerTimeDelta(timestamp: string) {
    this._logger.traceCall('calculateServerTimeDelta', timestamp);

    if (!timestamp) {
      this._logger.error('No timestamp in authentication data');
    }

    const serverNow = moment(timestamp) as any;
    const clientNow = moment() as any;

    this.serverTimeDelta = serverNow - clientNow;
  }

  public uuid(): string {
    return uuid4();
  }

  public get events() {
    return Events;
  }

  public get EVENT_LIST() {
    return EVENT_LIST;
  }

  public get EVENT_LIST_DEPRECATED() {
    return EVENT_LIST_DEPRECATED;
  }

  @action setInitialization(initialized) {
    this.initialized = initialized;
  }

  public get factory() {
    return Factory;
  }
}


