import 'whatwg-fetch';
import generateUuid from 'uuid';
import { b64EncodeUnicode } from '../Util/Base64';
import MultichannelSdk from '../../api';
import Compareable from '../../Comparable';

/**
 * Handles calling the EMS REST-Service, also known as TransactionManager.
 *
 * @see {@link http://loadbalancer1.a.office.4com.de/api-docs/}
 */
export default class EmsRestService extends Compareable {
  _api: MultichannelSdk;
  _baseUrl;
  _functionalFieldsEndpoint;
  _historyEndPoint;
  _logger;
  _sourceIdentifierEndpoint;
  _transactionsEndpoint;
  _workItemEndPoint;
  _workitemExternalObjectsEndpoint;
  _attachmentsEndpoint;

  /**
   * Instantiates a new EmsRestService.
   *
   * @param {VierComApi} api - Reference to the api this belongs to
   */
  constructor(api: MultichannelSdk) {
    super();
    this._api = api;
    this._logger = this._api.debug('api.network.emsRestService');

    this._baseUrl = this._api.config.urls.getAccessManagerFor(this._api.services?.activeService);

    this._transactionsEndpoint = 'transactions/';
    this._historyEndPoint = 'history/';
    this._workItemEndPoint = 'workitems/';
    this._workitemExternalObjectsEndpoint = 'workitemExternalObjects/';
    this._sourceIdentifierEndpoint = 'sourceIdentifier';
    this._functionalFieldsEndpoint = 'functionalfields/';
    this._attachmentsEndpoint = 'attachments/';
  }

  async fetch(url, method = 'GET', payload = null, additionalHeaders = {}): Promise<Response> {
    const requestId = generateUuid();
    const config = {
      method,
      headers: {
        'Request-Id' : requestId,
        Authorization: `Basic ${b64EncodeUnicode(
          `agent_${this._api.auth.me.id}:${this._api.sessionKey}`,
        )}`,
        ...additionalHeaders,
      },
    };

    url = this._baseUrl + url;

    if (method !== 'GET') {
      // @ts-ignore
      config.body = payload;
    }

    this._logger.trace('sending', {
      url,
      method,
      config,
      requestId
    });

    const response = await fetch(url, config);
    this._logger.trace('received status', {
      status: response?.status || null,
      requestId
    });

    if (response.status === 401) {
      return Promise.reject();
    }

    return response;
  }

  async fetchText(url, method = 'GET', payload = null, additionalHeaders = {}) {
    const fetchResult = await this.fetch(url, method, payload, additionalHeaders);

    if (fetchResult.status !== 200) {
      throw {
        message: 'Response Error',
        payload: fetchResult
      };
    }

    const response = fetchResult ? fetchResult.text() : '';

    this._logger.trace('received body', response);

    return response;
  }

  /**
   * Gets all functional fields for the customer.
   *
   * @see
   *
   * @returns {XML}
   */
  getFunctionalFields() {
    this._logger.traceCall('getTransactions');
    return this.fetchText(this._functionalFieldsEndpoint);
  }

  getSourceIdentifier(umsMailInputId, groupId, agentId) {

    this._logger.traceCall('getSourceIdentifier', {
      umsMailInputId,
      groupId,
      agentId
    });

    let additionalHeader = {};

    if (umsMailInputId !== undefined) {
      additionalHeader = {
        umsMailInputId: `${umsMailInputId || ''}`
      };
    }

    return this.fetchText(this._sourceIdentifierEndpoint, 'GET', null, {
      groupId: `${groupId}`,
      agentId,
      ...additionalHeader
    });
  }

  /**
   * Gets the transaction with the given uuid.
   *
   * @see {@link http://loadbalancer1.a.office.4com.de/api-docs/#!/v1/Acquire_a_transaction}
   *
   * @param {String} uuid
   * @returns {*}
   */
  getTransaction(fetchUuid) {
    this._logger.traceCall('getTransaction', fetchUuid);
    return this.fetchText(this._transactionsEndpoint + fetchUuid);
  }

  /**
   * Gets all transactions for the agent.
   *
   * @see {@link http://loadbalancer1.a.office.4com.de/api-docs/#!/v1/Acquire_transactions}
   *
   * @returns {XML}
   */
  getTransactions() {
    this._logger.traceCall('getTransactions');
    return this.fetchText(this._transactionsEndpoint);
  }

  async getAttachmentInfo(id) {
    if (id) {
      this._logger.traceCall('getAttachmentInfo', id);
      return this.fetchText(this._attachmentsEndpoint + id, 'GET', null, {
        onlyInfo: true,
      });
    }
  }
  async getAttachment(id) {
    if (id) {
      this._logger.traceCall('getAttachmentInfo', id);
      const result = await this.fetch(this._attachmentsEndpoint + id, 'GET', null, {
        onlyInfo: false,
      });
      return await result.blob();
    }
  }
  /**
   * Creates a new comment for the transaction with the given uuid.
   *
   * @see {@link https://gitlab.4com.de/ems/xml_spec/blob/master/transactions_example.xml} for the expected format of comment (note nodes)
   * @see {@link http://loadbalancer1.a.office.4com.de/api-docs/#!/v1/Add_a_history_entry_in_transaction}
   *
   * @param {String} transactionUuid
   * @param {XML} comment
   * @returns {*}
   */
  postComment(transactionUuid, comment) {
    this._logger.traceCall('postComment', { transactionUuid, comment });
    return this.fetch(this._historyEndPoint + transactionUuid, 'POST', comment);
  }

  postWorkitem(transactionUuid, workitemXML) {
    this._logger.traceCall('postWorkItem', { transactionUuid, workitemXML });
    return this.fetch(this._workItemEndPoint + transactionUuid, 'POST', workitemXML);
  }

  putWorkitem(transactionUuid, workitemXML) {
    this._logger.traceCall('putWorkItem', { transactionUuid, workitemXML });
    return this.fetch(this._workItemEndPoint + transactionUuid, 'PUT', workitemXML);
  }

  async postNewTransaction(uuid: string, transactionXml: string, transactionHeader: any) {
    this._logger.traceCall('postNewTransaction', uuid);
    return this.fetch(`${this._transactionsEndpoint}${uuid}`, 'POST', transactionXml, transactionHeader);
  }


  getWorkitemAttachment(uuid) {
    this._logger.traceCall('fetching Attachment', uuid);
    return this.fetch(this._workitemExternalObjectsEndpoint + uuid);
  }

  async postWorkitemAttachment(workitemUuid, file: File) {
    this._logger.traceCall('postWorkItem', {
      workitemUuid,
      content: `${file.name} ${file.type}`,
    });

    const response = await this.fetch(
      this._workitemExternalObjectsEndpoint + workitemUuid,
      'POST',
      await file.arrayBuffer(),
      {
        'Content-Type': file.type,
        customerId    : this._api.customer.id,
      },
    );

    if (response.status === 201) {
      return true;
    }
    return false;
  }
}
