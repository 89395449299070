import Question from './Question';
import { ScopedLogger } from '@core/logger';

export default class QuestionLinks extends Question {
  urls;

  constructor(
    protected _logger: ScopedLogger,
    pageData,
  ) {
    super(
      _logger,
      pageData,
    );
    const data = pageData[4];
    this.urls = data.map(item => item[1]);
    return this._proxy;
  }
}
