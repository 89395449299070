import MultichannelSdk from '../../api';
import { EVENT_LIST } from '../constants';
import Compareable from '../../Comparable';
import Events from '@core/events';

/**
 * The class Connection handles the network state
 *
 * Network Information API
 * https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/type
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine/Online_and_offline_events
 */
export default class Connection extends Compareable {
  _api: MultichannelSdk;
  _connection;
  _online;

  /**
   *
   * @param {VierComApi} api - Reference to the api this belongs to
   */
  constructor(_api: MultichannelSdk) {
    super();
    this._api = _api;
    this._connection =
      //@ts-ignore
      navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    this._online = true;

    if (this._connection) {
      this._connection.onchange = this._handleConnectionChange;
    }

    if (window) {
      window.addEventListener('offline', () => this._onConnectionOffline());
      window.addEventListener('online', () => this._onConnectionOnline());
    }
  }

  //handle change of connection type here / type ( wifi...)
  _handleConnectionChange() {
    return null;
  }

  _onConnectionOffline() {
    this._online = false;
    Events.trigger(EVENT_LIST.SDK_NETWORK_OFFLINE, false);
  }

  _onConnectionOnline() {
    this._online = true;
    Events.trigger(EVENT_LIST.SDK_NETWORK_ONLINE, true);
  }

  get online() {
    return this._online;
  }
}
