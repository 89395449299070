import Question from './Question';
import { action, observable } from 'mobx';
import { ScopedLogger } from '@core/logger';

export default class QuestionCheckBox extends Question {
  options;
  @observable selected;

  @action public evaluate() {
    this.isEvaluated = true;
    this.hasError =
      this.isMandatory &&
      (typeof this.selected === 'undefined' || Object.entries(this.selected).length === 0);
  }

  @action reset() {
    super.reset();
    this.selected = observable({});
  }

  @action setValue(controlValue) {
    controlValue.lvvaluepairsList.forEach(valuePair => {
      const id = parseInt(valuePair.id, 10);
      this.selected[id] = this.options.find(opt => opt.id === id);
    });
  }

  constructor(protected _logger: ScopedLogger, pageData) {
    super(_logger, pageData);
    const data = pageData[4];
    this.options = data[1].map(optionData => {
      const label =
        optionData[2].substr(-1) === ''
          ? optionData[2].substr(0, optionData[2].length - 1)
          : optionData[2];

      const option: QuestionOption = {
        id        : parseInt(optionData[0], 10),
        label,
        value     : optionData[1],
        nextPageId: parseInt(optionData[3], 10),
      };
      return option;
    });

    this.selected = observable({});

    return this._proxy;
  }
}

export interface QuestionOption {
  id: number;
  label: string;
  value: string;
  nextPageId: number;
}
