import {
  observable,
  computed,
  ObservableMap,
  action
} from 'mobx';
import Service from './Service';

/**
 * The Services class was created to handle multiple services like acd and obm to support
 * blending in one tab.
 */
export class Services {
  @observable activeService: string;
  @observable serviceList: ObservableMap<string, Service>;
  @observable defaultServiceName: string;

  constructor() {
    this.serviceList = new ObservableMap();
    this.defaultServiceName = '';
  }

  @computed get currentService(): Service | null {
    return this.serviceList?.get(this.activeService) || null;
  }

  @computed get defaultService(): Service | null {
    return this.serviceList?.get(this.defaultServiceName) || null;
  }

  @computed get acdActive(): boolean {
    return this.activeService === 'acd';
  }

  @computed get obmActive(): boolean {
    return this.activeService === 'obm';
  }

  @action public switchService(name: string | null = null): void {
    if (name && name === this.activeService) {
      return;
    }

    const listOfServices = Array.from(this.serviceList).map(service => service[0]).
      filter(service => name ?
        service === name :
        service !== this.activeService
      ) || [];

    if (listOfServices.length) {
      const oldService = this.currentService?.package;
      oldService?.onPause();
      this.activeService = listOfServices[0];
      this.currentService?.package?.onResume(oldService);
    }
  }

  @action public enableService(serviceName: string): boolean {
    if (this.serviceList.has(serviceName)) {
      this.activeService = serviceName;
      return true;
    }
    return false;
  }

  @action public createService(service: any, defaultService: boolean = false): Service {
    const createdService: Service = new Service(service);
    this.serviceList.set(service.serviceName, createdService);

    if (defaultService) {
      this.defaultServiceName = service.serviceName;
      this.activeService = service.serviceName;
    }
    return createdService;
  }

  @computed get acd(): Service | null {
    return this.serviceList?.get('acd') || null;
  }

  @computed get obm(): Service | null {
    return this.serviceList?.get('obm') || null;
  }

  @computed get blending(): Service | null {
    return this.serviceList?.get('blending') || null;
  }

  @computed get activeCustomer(): any {
    return this.currentService?.customer ||
      this.defaultService?.customer ||
      null;
  }

  @computed get activeUser(): any {
    return this.currentService?.user ||
      this.defaultService?.user ||
      null;
  }

  public getService(serviceName: string): Service | null {
    return this.serviceList?.get(serviceName) || null;
  }

  hasService(serviceName: string): boolean {
    return this.serviceList?.has(serviceName) || false;
  }

  isServiceActive(serviceName: string): boolean {
    return serviceName === this.activeService;
  }

}
