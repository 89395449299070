import DE from './de.json';
import 'moment/locale/de';
import EN from './en.json';
import 'moment/locale/en-gb';
import ES from './es.json';
import 'moment/locale/es';
import * as PL from './pl.json';
import 'moment/locale/pl';
import IT from './it.json';
import 'moment/locale/it';
import FR from './fr.json';
import 'moment/locale/fr';
import EL from './el.json';
import 'moment/locale/el';

import MultichannelSdk from '../../api';
import { createProxy } from '../Proxy';
import moment from 'moment';
import Compareable from '../../Comparable';
import i18n from 'i18next';

const AVAILABLE_LANGUAGES = {
  EN: {
    translation: EN
  },
  DE: {
    translation: DE
  },
  ES: {
    translation: ES
  },
  PL: {
    translation: PL
  },
  IT: {
    translation: IT
  },
  FR: {
    translation: FR
  },
  EL: {
    translation: EL
  }
};

//@ts-ignore
const BROWSER_LANGUAGE = (navigator.language || navigator.userLanguage)
  .toLowerCase()
  .split(/[-_]+/);

const DEFAULT_LANGUAGE = BROWSER_LANGUAGE[0] ? BROWSER_LANGUAGE[0].toUpperCase() : 'EN';

/**
 * A very basic language system.
 *
 */
export default class Language extends Compareable {
  /**
   * Returns the currently set locale.
   *
   * @returns {string}
   */
  get locale() {
    return this._language.toLowerCase();
  }

  get missingTranslations(): {} {
    return { ['Missing translations : ' + this._language]: [...this._missingTranslations] };
  }
  _api: MultichannelSdk;
  _language: string;
  _logger: any;
  _missingTranslations: Set<string> = new Set<string>();
  _proxy: any;

  /**
   * Instantiates a new Language.
   *
   * @param {VierComApi} api - Reference to the api this belongs to
   */
  constructor(api: MultichannelSdk) {
    super();
    this._proxy = createProxy(this);

    this._api = api;
    this._logger = api.debug('api.language');

    this.setLanguage(api.config.language ? api.config.language : DEFAULT_LANGUAGE);
    return this._proxy;
  }

  /**
   *
   * @param translation
   * @param replacements
   * @returns {*}
   */
  _replacePlaceholders(translation, replacements) {
    const REPLACEMENTS_DONT_MATCH_PLACEHOLDERS =
      Array.isArray(replacements) && replacements.length !== translation.split('%%').length - 1;
    const REPLACEMENTS_MISSING = !Array.isArray(replacements) && translation.indexOf('%%') === -1;

    if (REPLACEMENTS_DONT_MATCH_PLACEHOLDERS || REPLACEMENTS_MISSING) {
      this._logger.debug(
        'The number of arguments to replace does not match the number of placeholders ' +
          `in the string: ${JSON.stringify({ translation, replacements })}`,
      );
      return translation;
    }

    if (!Array.isArray(replacements)) {
      // Only on replacement and placeholder.
      return translation.replace('%%', replacements);
    }

    return !replacements.length
      ? translation
      : this._replacePlaceholders(
        translation.replace('%%', replacements[0]),
        replacements.slice(1),
      );
  }

  /**
   * Sets the language to be used by the language system.
   * Languages are defined by two letter uppercase abbreviations (e.g. DE, EN)
   *
   * @param {string} language - the language to be set
   */
  setLanguage(language: string) {
    this._logger.traceCall('setLanguage', language);

    i18n
      .init({
        resources    : AVAILABLE_LANGUAGES,
        fallbackLng  : 'EN',
        lng          : language,
        keySeparator : false,
        interpolation: {
          escapeValue: false
        },
        saveMissing      : true,
        missingKeyHandler: (_lng, _ns, key, _fallBackValue) => {
          this._missingTranslations.add(key);
        }
      }, (err) => {
        if(err) return this._logger.error('Can\'t Initialize i18next', {err});
        this._logger.trace('i18next initialized');
      });

    document.querySelector('html')?.setAttribute('lang', language.toLowerCase().substr(0, 2));
    moment.locale(language.toLowerCase().substr(0, 2));
    this._language = language;
  }

  /**
   * Translates the entry with the given key to the currently used language.
   *
   * @param {string} key - The phrase to be translated.
   * @param {string|null} replacements - Values for placeholders in the translation template.
   * @returns {string}
   */
  translate(key = '', replacements = null) {
    const localKey = key.replace(/\s/g, '_');
    const result = i18n.t(localKey, localKey);

    if (replacements) {
      return this._replacePlaceholders(result, replacements);
    }
      
    return result;
  }
}
