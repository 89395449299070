import { fromEvent } from 'rxjs';
import { computed, observable, runInAction, action } from 'mobx';
import { ScopedLogger } from '@core/logger';
import { EVENT_LIST, WHITELIST_SEARCH_PARAMS } from '../constants';
import Compareable from '../../Comparable';
import MultichannelSdk from '../../api';
import { createProxy } from '../Proxy';
import { FactoryMessageResponse } from '@core/messages';
import { SALESFORCE_WHITELIST_SEARCH_PARAMS } from '@multichannel/salesforce-adapter/src/constants';
import Events from '@core/events';
import Actions from '@core/actions';

export enum ClientCloseBehavior {
  USER_DECIDES_CLOSING = '0',
  USER_AUTOMATICALLY_LOGGED_OFF = '1',
  USER_STAY_LOGGED_IN = '2'
}

export const LoginResult = {
  _LOGINRESULT_DEFAULT       : 0,
  ASLRINVALID                : -1,
  ASLRSUCCESS                : 0,
  ASLRDATAINVALID            : 1,
  ASLRALREADYLOGGEDIN        : 2,
  ASLRDATABASEFAILURE        : 3,
  ASLRMSGVERSIONTOOLOW       : 4,
  ASLRAGENTHAVENOGROUP       : 5,
  ASLRNOFREELICENCE          : 6,
  ASLRSUPERVISORNOTACTIVE    : 7,
  ASLRNOSUPERVISORPRIVILEGES : 8,
  ASLRWRONGSUPERVISORPASSWORD: 9,
  ASLRCOMERROR               : 10,
  ASLRWRONGUSERID            : 11,
  ASLRWRONGACDGRUPPE         : 12,
  ASLRUNKNOWNCUSTOMERSESSION : 13,
  ASLRALLGROUPSBLOCKED       : 14,
  ASLRPASSWORDEXPIRED        : 15,
  ASLRUSERLOCKEDOUT          : 16,
  ASLRINVALIDCREDENTIALS     : 17,
  ASLRMULTITAB               : 98,
  ASLRSYSTEMFAILURE          : 99
};

// const LoginResult = LoginResponseMessage.AsLoginRespMsg.LoginResult;
const LoginResultWrongBackend = 900;
const LoginNoPhoneNumber = 200;
const SDK_PRIORITY: number = 20;

/**
 * Module to handle the authorisation of at user at the agentserver an to store the currently authenticated user.
 *
 */
export default class Auth extends Compareable {
  @observable private _authenticating: boolean = false;

  private _useOAuth2: boolean = false;
  private _useOAuthRedirectParameter: Array<string> = [];
  private _useOAuthSearchParamsWhitelist: Array<string> = WHITELIST_SEARCH_PARAMS;
  private _oAuth2Key: string | null = null;
  private _oAuth2State: string | null = null;

  private _error: null | string = null;
  private _notRespondingTimeOut: any = null;
  private _logger: ScopedLogger;
  private _config: any;
  private _api: MultichannelSdk;
  private _proxy;

  public agentServerVersion: string | null;
  public loginResult: number | null;
  public passwordChangeUrl: string;
  public windowUnload;
  public windowBeforeUnload;
  public windowVisibleChange;
  public windowKeydown;
  public logoutUrl: string = null;

  constructor(api: MultichannelSdk) {
    super();
    this._proxy = createProxy(this);

    this.agentServerVersion = null;
    this.passwordChangeUrl = '';
    this._api = api;
    this._logger = this._api.debug('auth');
    this._useOAuthRedirectParameter = [];

    runInAction(() => {
      this._authenticating = false;
    });

    this._useOAuthSearchParamsWhitelist = [
      ...WHITELIST_SEARCH_PARAMS,
      ...SALESFORCE_WHITELIST_SEARCH_PARAMS,
      ...api.config?.flags?.searchParamsWhitelist || []
    ];

    // Disable oauth when flag is false in config
    if (api.config?.flags?.oauth2 === false) {
      this.useOAuth2 = false;
    } else {
      this.useOAuth2 = true;
    }

    if (this.useOAuth2) {
      if (api.config.flags?.identityProvider && !location.search.includes('logout=true')) {
        this._useOAuthRedirectParameter.push(`kc_idp_hint=${api.config.flags.identityProvider}`);
      }

      // Check if we have further oauth parameter
      if (api.config.flags?.oauth2parameter) {
        if (Array.isArray(api.config.flags.oauth2parameter)) {
          this._useOAuthRedirectParameter = [
            ...this._useOAuthRedirectParameter,
            ...api.config.flags.oauth2parameter
          ];
        } else {
          this._useOAuthRedirectParameter.push(api.config.flags.oauth2parameter);
        }
      }



      // set the authenticatin flag directly since we need the first
      // response from session continue before we are in the authenticating mode
      this.authenticating = true;
    }
    const getMessage = this._api.messageHandler.messagesByType;

    getMessage(2).subscribe(res => this._handleInitialisationResponse(res));

    Events.on(EVENT_LIST.SDK_RESPONDING_ERROR, () =>
      api.messageHandler.reconnect(),
    );

    Actions.register('sdk.authenticate', _credentials => null);
    Actions.register('sdk.auth.logout', (originalFn:any) => {
      this._logout();
      originalFn();
    }, SDK_PRIORITY);
    Actions.register('sdk.auth.reset', () => null);
    Actions.register('sdk.auth.relogin', async () => null);
    Actions.register('sdk.auth.initiated', _res => null);
    Actions.register('sdk.auth.stop', () => null);
    Actions.register('sdk.auth.loginresponse', _response => null);

    return this._proxy;
  }

  /**
   * The currently logged in user.
   *
   * @type {User|null}
   */
  @computed public get me(): any {
    return this._api.services?.activeUser ||
      null;
  }


  public get useOAuthRedirectParameter(): string {
    if (!this._useOAuthRedirectParameter.length) {
      return '';
    }
    return `&${this._useOAuthRedirectParameter.join('&')}`.replace('&&', '&');
  }

  @computed public get useOAuth2(): boolean {
    return this._useOAuth2;
  }

  public get urlToLogout(): string {
    const url = new URL(location.href);
    url.searchParams.set('logout', 'true');

    return `${this.logoutUrl}?redirect_uri=${encodeURIComponent(url.toString())}`;
  }

  public set useOAuth2(value: boolean) {
    this._useOAuth2 = value;

    if (value) {
      const originalUrl: string = location.href;
      const url = new URL(originalUrl);

      this._oAuth2State = url.searchParams.get('session_state') || null;
      this._oAuth2Key = url.searchParams.get('code') || null;

      // Reset search params except all from the whitelist ( e.g. salesforce )
      const replacedSearchParams = new URLSearchParams('');

      url.searchParams.forEach((value: string, key: string) => {
        if (this._useOAuthSearchParamsWhitelist.includes(key)) {
          replacedSearchParams.append(key, value);
        }
      });

      url.search = replacedSearchParams.toString();

      window.history.pushState('', '', url.toString());

      this._logger.trace('useAuth with: state=' + this._oAuth2State + '; key= ' + this._oAuth2Key, originalUrl);
    }
  }

  @computed public get oAuthParameter() {
    if (this._useOAuth2 && this._oAuth2Key && this._oAuth2State) {
      return {
        key  : this._oAuth2Key,
        state: this._oAuth2State,
        url  : location.href
      };
    } else {
      return null;
    }
  }

  @action resetOAuthParameter() {
    this._oAuth2State = null;
    this._oAuth2Key = null;

    // this gets called after recieving the loginresponse, so we can remove the logout parameter
    // to prevent the user to stay in the sign in page from our keycloak

    const url = new URL(location.href);
    url.searchParams.delete('logout');
    window.history.pushState('', '', url.toString());
  }

  @computed public get isAuthenticated(): boolean {
    return this.me && this.me.isConfigured;
  }

  @computed get authenticating(): boolean {
    return this._authenticating;
  }

  @action stopAuthenticating() {
    this._authenticating = false;
    this.clearNotRespondingTimeout();
  }

  set authenticating(value: boolean) {
    runInAction(() => {
      this._authenticating = value;
    });
  }


  get error(): null | string {
    return this._error;
  }

  set error(error: string) {
    this._error = error;
  }

  public get loginError() {
    return this.getLoginError(this.loginResult);
  }

  /**
   * Initiate a list of sdk instances from local storage and add the current instance
   * to it. The current instance will always be on the top of this list.
   */
  public initiateInstanceList() {
    const disableCloseBehaviour: boolean =
      (this._config &&
        this._config.flags &&
        this._config.flags.disableCloseBehaviour) ||
      false;

    this.windowUnload = fromEvent(window, 'unload').subscribe(() => {
      if (this.me?.agentCloseBehaviour === -1) {
        this.logout();
      }
    });

    this.windowBeforeUnload = fromEvent(window, 'beforeunload').subscribe((event: any) => {

      const instanceList = this._api.instanceList;
      if (disableCloseBehaviour || instanceList.length > 1) return '';

      if (this._api.customer?.clientCloseBehaviour === ClientCloseBehavior.USER_DECIDES_CLOSING) {
        if (this.me?.agentCloseBehaviour === 0) {
          Events.trigger(EVENT_LIST.SDK_CLOSING_REQUESTED);
          event.returnValue = 'BEFOREUNLOAD';
          return 'BEFOREUNLOAD';
        }
      } else if (this.me && this._api.customer?.clientCloseBehaviour === ClientCloseBehavior.USER_AUTOMATICALLY_LOGGED_OFF) {
        if (this.me.agentCloseBehaviour === 2) {
          this.me.agentCloseBehaviour = 0;
        } else {
          this.me.agentCloseBehaviour = -1;
        }
      }
    });

    this.windowKeydown = fromEvent(window, 'keydown').subscribe(event => {
      const instanceList = this._api.instanceList;
      const pushedRefresh: boolean =
        //@ts-ignore
        (event.ctrlKey && event.key === 'r') || event.key === 'F5';

      if (instanceList.length === 1 && pushedRefresh) {
        this.me.agentCloseBehaviour = 2;
      }

    });
  }


  public getLoginError(errorCode: number): string {
    let loginErrorTranslationKey = '';

    switch (errorCode) {
    case LoginResult.ASLRDATAINVALID:
    case LoginResult.ASLRINVALIDCREDENTIALS:
      loginErrorTranslationKey = 'Die von Ihnen eingegebenen Anmeldedaten sind ungültig!';
      break;
    case LoginResult.ASLRALREADYLOGGEDIN:
      loginErrorTranslationKey = 'Ein_anderer_User_ist_bereits_mit_dieser_PIN_angemeldet';
      break;
    case LoginResult.ASLRMSGVERSIONTOOLOW:
      loginErrorTranslationKey = 'Die_Programmversion_dieses_Clients_ist_nicht_mehr_aktuell';
      break;
    case LoginResult.ASLRNOFREELICENCE:
      loginErrorTranslationKey = 'Momentan_werden_alle_Lizenzen_in_Anspruch_genommen_';
      break;
    case LoginResult.ASLRSUPERVISORNOTACTIVE:
      loginErrorTranslationKey = 'Die_Coach_Funktion_ist_eine_Zusatzfunktion';
      break;
    case LoginResult.ASLRNOSUPERVISORPRIVILEGES:
      loginErrorTranslationKey = 'Sie_haben_keine_Coach_Berechtigung';
      break;
    case LoginResult.ASLRWRONGSUPERVISORPASSWORD:
      loginErrorTranslationKey = 'Sie_konnten_nicht_angemeldet_werden_';
      break;
    case LoginResult.ASLRCOMERROR: // is not used by the agentserver atm
      loginErrorTranslationKey =
          'Die_ACD_ist_zurzeit_nicht_erreichbar__Bitte_wenden_Sie_sich_an_Ihren_Serviceprovider';
      break;
    case LoginResult.ASLRWRONGUSERID:
      loginErrorTranslationKey = 'Die_von_Ihnen_eingegebene_Benutzerkennung_ist_ungültig';
      break;
    case LoginResult.ASLRALLGROUPSBLOCKED:
      loginErrorTranslationKey =
          'Sie_haben_alle_aktiven_Kampagnen_geblockt__Möchten_Sie_die_Blockierungen_aufheben_'; //TODO: wenn wir kampagnen angehen - kommt hier im PCWin ein Button?
      break;
    case LoginResult.ASLRPASSWORDEXPIRED:
      loginErrorTranslationKey = 'Das_Passwort_ist_abgelaufen';
      break;
    case LoginResult.ASLRUSERLOCKEDOUT:
      loginErrorTranslationKey = 'Das_Passwort_ist_gesperrt';
      break;
    case LoginResult.ASLRSYSTEMFAILURE:
      loginErrorTranslationKey = 'Es_ist_ein_Systemfehler_aufgetreten_';
      break;
    case LoginResult.ASLRAGENTHAVENOGROUP:
    case LoginResult.ASLRWRONGACDGRUPPE:
      //TODO: other message when using outbound manager
      loginErrorTranslationKey = 'Sie_sind_keiner_Gruppe_zugeordnet';
      break;
    case LoginResultWrongBackend:
      loginErrorTranslationKey = 'Falsche_Konfiguration_für_diesen_Endpunkt';
      break;
    case LoginNoPhoneNumber:
      loginErrorTranslationKey = 'ExtensionIsAlreadyInUse';
      break;
    case LoginResult.ASLRMULTITAB:
      loginErrorTranslationKey = 'Login_Error_Multi_Tab';
      break;
    default:
      loginErrorTranslationKey = 'Fehler bei Login';
      break;
    }
    return this._api.language.translate(loginErrorTranslationKey);
  }

  /**
   * Handle Initialisation
   *
   * Note: this has been replaced by a static versioning which is implemented as a fallback
   * directly within the MessageHandler class. When we use the message interface version
   * received with this message we'll receive the latest "stable" version. That includes
   * automatic upgrades out of our control and limits us to the stable versions.
   * This is a bit experimental though, keep an eye on it.
   *
   * Theoretically the agent server should respond with the next best available version
   * if we request an unavailable one, so, theoretically, nothing should ever go wrong.
   * Theoretically.
   *
   * @see {@link https://gitlab.4com.de/agentenserver/protobuf-messages/blob/master/proto3/2_AsInitialisationRespMsg.proto}
   * @see {@link https://wiki.4com.de/display/Entwicklung/00002_AsInitialisationResp}
   * @param data
   */
  private _handleInitialisationResponse(res: FactoryMessageResponse) {
    const initialisationData = res?.obj;
    const sdk = this._api;
    // set the agentserver version to be used for further communication
    // this._messageHandler.version = message.Version;
    this.handleInitialisationResponse(initialisationData);
    sdk.calculateServerTimeDelta(initialisationData.timestamp);
    sdk.setInitialization(true);
    Events.trigger([sdk.EVENT_LIST.SDK_INITIALIZED, 'initialized']);
    Actions.invoke('sdk.auth.relogin', initialisationData);

  }

  /**
   *
   * @param initialisationData
   */
  public handleInitialisationResponse(initialisationData): void {

    const tokens = (sessionStorage.getItem('agentservertokens') || '').split(';').slice(0, 9);
    tokens.push(initialisationData.agentservertoken);
    sessionStorage.setItem('agentservertokens', tokens.join(';'));

    this.agentServerVersion = initialisationData.agentserverversion;
    this.passwordChangeUrl = initialisationData.passwordchangeurl;
  }

  public _logout() {
    const url = new URL(location.href);
    url.hash = '#/';
    window.history.pushState('', '', url.toString());
  }

  public logout() {
    Actions.invoke('sdk.auth.logout');
  }

  public reset() {
    Actions.invoke('sdk.auth.reset');
  }

  public setLogoutUrl(url) {
    if (this.useOAuth2) this.logoutUrl = url;
  }

  public setRespondTimeout(timeInSeconds) {
    if (this._notRespondingTimeOut) {
      clearTimeout(this._notRespondingTimeOut);
    }
    this._notRespondingTimeOut = setTimeout(this.onRespondError(this), timeInSeconds * 1000);
  }

  public clearNotRespondingTimeout() {
    clearTimeout(this._notRespondingTimeOut);
    Events.trigger([EVENT_LIST.SDK_RESPONDING_CLEAR, 'clear: got response']);
  }

  public onRespondError = (auth) => () => {
    auth.reset();
    Events.trigger([EVENT_LIST.SDK_RESPONDING_ERROR, 'error: no response']);
  };

}
