import { createProxy } from '../Proxy';
import MultichannelSdk from '../../api';
import { ScopedLogger } from '@core/logger';
import Page from './Page';
import { observable, computed, action } from 'mobx';
import Compareable from '../../Comparable';
import CallFlow from './CallFlow';

/**
 * The customer Call Guide
 */
export default class CallGuide extends Compareable {
  _api: MultichannelSdk;
  _proxy;

  @observable _isLoaded = false;
  /**
   * The current step, 0 based Index
   */
  @observable _activeStep: number;
  /**
   * the Current active Page
   */
  @observable _activePage: Page;

  _id: number;
  _name: string;
  _updateDate: string;
  @observable _pages: Page[];
  @observable _activePages: Page[];
  @observable _pagesById;
  @observable _allQuestions;

  constructor(
    private _callFlow: CallFlow,
    private _logger: ScopedLogger,
  ) {
    super();
    this._proxy = createProxy(this);

    return this._proxy;
  }

  @computed get allQuestions() {
    return this._allQuestions;
  }

  /**
   *
   * @param controlValues
   */
  @action public preset(controlValues) {
    this._callFlow.disable();

    controlValues.forEach(controlValue => {
      const controlId = parseInt(controlValue.controlid, 10);
      if (typeof this._allQuestions[controlId] !== 'undefined') {
        this._allQuestions[controlId].setValue(controlValue);
      }
    });

    let limit = 100;
    while (this.evaluateNext() && limit > 0) {
      limit--;
      this.evaluateNext();
    }
  }

  /**
   * Return true, if the Guide is filled out completely
   *
   * All active Pages must be valid and last page been reached.
   */
  @computed public get isValid(): boolean {
    // The last page can only be reached if all
    // previous pages are valid
    return this.isLastPage
      && !this._activePage.hasError
      && this._activePage.isEvaluated;
  }

  @computed public get isLastPage(): boolean {
    return this._activePage.nextPageId === 0
      && this._activePage.questionNextPageId === 0;
  }

  /**
   * This Method validates the current Page
   * and if existing and all Fields are valid, leads to the next Page.
   */
  @action evaluateNext() {
    if (this._callFlow.isRunning) {
      const nextId = this._activePage.evaluatedNext();
      if (nextId > 0) {
        const nextPage = this._pagesById[nextId];
        this._activePages.push(nextPage);
      } else {
        return;
      }
    }

    this.activeStep++;
    return true;
  }

  /**
   * If not on the first Page, this Method leads to the previous page
   * and removes the current page. The current page data is cached.
   */
  @action evaluateBack() {
    if (this.activeStep === 0) return;
    this.activeStep--;

    if (this._callFlow.isRunning) {
      this._activePages.pop();
    }
  }

  @computed get name() {
    return this._name;
  }

  @computed get id() {
    return this._id;
  }

  @computed get updateDate() {
    return this._updateDate;
  }

  @computed get pages() {
    return this._pages;
  }

  @computed public get isRunning(): boolean {
    return this._callFlow.isRunning;
  }

  set activeStep(index) {
    const pages = this.isRunning ? this._activePages : this._pages;

    if (pages.length >= index + 1 && typeof pages[index] !== 'undefined') {
      this._activeStep = index;
      this._activePage = pages[index];
    }
  }

  @computed get activeStep() {
    return this._activeStep;
  }

  @computed get activePage() {
    return this._activePage;
  }

  @computed get activePages() {
    return this._activePages;
  }

  /**
   * TRUE, if the Call Guide has been finished Loading
   */
  @computed get isLoaded() {
    return this._isLoaded;
  }

  @action reset() {
    this.pages.forEach(page => page.reset());
    if (this._activePages.length > 1) {
      this._activePages = observable([this.pages[0]]);
      this.activeStep = 0;
    }
    this._callFlow.enable();
  }

  /**
   * Compare the last update string to check if the callguide is outdated
   * @param lastUpdate
   * @returns
   */
  public isOutdated(lastUpdate: string): boolean {
    return this._updateDate !== lastUpdate;
  }

  /**
   * Return all filled out Control Values from
   * all visited Pages
   *
   * This Method returns only those values which were filled
   * out. Pages not visited are skipped.
   */
  @computed public get controlValues() {
    return [].concat(...this.activePages.map(p => p.controlValues));
  }

  /**
   * Handle incoming Call Guide Data
   *
   * @see {@link https://gitlab.4com.de/agentenserver/protobuf-messages/blob/master/proto3/11051_AsAcdCustomerConfigRespMsg.proto} for customerConfigResponse
   */
  @action _init(dataItems) {
    this._pagesById = observable({});
    this._activePages = observable([]);

    this._id = dataItems[1][0];
    this._name = dataItems[1][1];
    this._updateDate = dataItems[1][2];

    this._pages = observable(
      (dataItems[1][3].map &&
        dataItems[1][3].map(data => {
          const page = new Page(this._logger, data);
          this._pagesById[page.id] = page;
          return page;
        })) ||
      [],
    );

    if (this._pages.length > 0) {
      const allQuestions = {};

      [].concat(...this._pages.map(p => p.questions)).forEach(q => {
        allQuestions[q.id] = q;
      });

      this._allQuestions = allQuestions;
      this._activePages.push(this._pages[0]);
    }
    this.activeStep = 0;
    this._isLoaded = true;
  }

  @action init(dataItems) {
    this._init(dataItems);
  }
}
