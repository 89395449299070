import { createProxy } from '../Proxy';
import MultichannelSdk from '../../api';
import { ScopedLogger } from '@core/logger';
import Question, { QuestionType } from './Question';
import QuestionTextBox from './QuestionTextBox';
import QuestionLinks from './QuestionLinks';
import QuestionComboBox from './QuestionComboBox';
import QuestionCheckBox from './QuestionCheckBox';
import {
  observable,
  action,
  computed,
} from 'mobx';
import Compareable from '../../Comparable';

export default class Page extends Compareable {
  _api: MultichannelSdk;
  _proxy;

  @action public evaluate() {
    this.questions.forEach(question => {
      question.evaluate();
    });
    this.isEvaluated = true;
    this.hasError = typeof this.questions.find(q => q.hasError) !== 'undefined';
    if (!this.hasError) {
      const questionNext = this.questions.find(q => q.nextPageId > 0);
      this.questionNextPageId = questionNext ? questionNext.nextPageId : 0;
    }
  }

  @action evaluatedNext(): number {
    this.evaluate();
    if (!this.hasError) {
      const questionNext = this.questions.find(q => q.nextPageId > 0);
      this.questionNextPageId = questionNext ? questionNext.nextPageId : 0;
      if (this.questionNextPageId > 0) return this.questionNextPageId;
      return this.nextPageId;
    } else {
      return 0;
    }
  }

  /**
   * Return all filled out Control Values from
   * the current Page
   */
  @computed public get controlValues() {
    return this.questions.filter(q => typeof (q as any).value !== 'undefined'  || typeof (q as any).selected !== 'undefined');
  }

  id;
  name;
  @observable questions: Question[];
  description;
  decisionQuestion;
  @observable nextPageId;
  @observable questionNextPageId;
  smallView;

  @observable isEvaluated: boolean;
  @observable hasError: boolean;

  constructor(private _logger: ScopedLogger, data) {
    super();
    this._proxy = createProxy(this);
    this._init(data);
    this._preEvaluate();
    return this._proxy;
  }


  /**
   * This Method is needed to trigger Evaluation
   *
   * on the Last Page in the Callguide. If there is
   * no mandatory Question, the Evaluation cannot be triggered otherwise
   * and the Save button will never get enabled.
   */
  private _preEvaluate() {
    if (typeof this.questions.find(question => question.isPageSelector || question.isMandatory) === 'undefined') {
      this.evaluate();
    }
  }

  @action reset() {
    this.questions.forEach(question => question.reset());
    this.isEvaluated = undefined;
    this.hasError = undefined;
    this._preEvaluate();
  }

  @action private _init(dataItems) {
    this.id = parseInt(dataItems[0], 10);
    this.name = dataItems[1];
    this.questions = dataItems[2].map(data => this._createQuestion(data));
    this.description = dataItems[3];
    this.decisionQuestion = dataItems[4];
    this.nextPageId = parseInt(dataItems[5], 10);
    this.smallView = dataItems[6];
  }

  private _createQuestion(data) {
    switch (parseInt(data[3], 10)) {
    case QuestionType.TextBox:
      return new QuestionTextBox(this._logger, data);
    case QuestionType.ComboBox:
    case QuestionType.RadioButton:
      return new QuestionComboBox(this._logger, data);
    case QuestionType.CheckBox:
      return new QuestionCheckBox(this._logger, data);
    case QuestionType.LinkList:
      return new QuestionLinks(this._logger, data);
    default:
      return new Question(this._logger, data);
    }
  }
}
