import {
  SessionDescriptionHandlerConfiguration
} from 'sip.js/lib/platform/web/session-description-handler/session-description-handler-configuration';
import { defaultPeerConnectionConfiguration } from 'sip.js/lib/platform/web/session-description-handler/peer-connection-configuration-default';
import { SessionDescriptionHandler } from 'sip.js/lib/platform/web/session-description-handler/session-description-handler';

import VoIP  from './VoIP';

function mediaStream(): any {
  return (constraints: any): Promise<MediaStream> => {

    // if no audio or video, return a media stream without tracks
    if (!constraints.audio && !constraints.video) {
      return Promise.resolve(new MediaStream());
    }
    // getUserMedia() is a powerful feature which can only be used in secure contexts; in insecure contexts,
    // navigator.mediaDevices is undefined, preventing access to getUserMedia(). A secure context is, in short,
    // a page loaded using HTTPS or the file:/// URL scheme, or a page loaded from localhost.
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#Privacy_and_security
    if (navigator.mediaDevices === undefined) {
      return Promise.reject(new Error('Media devices not available in insecure contexts.'));
    }
    return navigator.mediaDevices.getUserMedia.call(navigator.mediaDevices, constraints);
  };
}


// export class SessionDescriptionHandlerE
export class SessionDescriptionHandlerExtended extends SessionDescriptionHandler {
  constructor(voip: VoIP, logger: any, mediaStream: any, options: any) {
    super(logger, mediaStream, options);

    this.peerConnection.onconnectionstatechange = (event) => {

      //@ts-ignore
      switch (event?.target?.connectionState) {
      case 'connected':
        voip.reconnecting = false;
        break;
      case 'disconnected':
        voip.reconnecting = true;
        break;
      case 'failed':
        voip.reconnecting = true;
        voip.session.sessionDescriptionHandler.close();
        //@ts-ignore
        voip.session._sessionDescriptionHandler = null;
        //@ts-ignore
        voip.session.setupSessionDescriptionHandler();
        voip.session.invite().then(() => {
          voip.handleAccept();
        }).catch(() => {
          voip.reconnectionFailed = true;
        });
        break;
      }

      //@ts-ignore
      logger.log(`Connection State changed ${event?.target?.connectionState}`);
      return undefined;
    };

    //@ts-ignore
    this.peerConnection.onsignalingstatechange = (event) => {
      //@ts-ignore
      logger.log(`Signaling State changed ${event?.target?.signalingState}`);
      return undefined;
    };

    //@ts-ignore
    this.peerConnection.onicegatheringstatechange = () => {
      return undefined;
    };

    //@ts-ignore
    this.peerConnection.oniceconnectionstatechange = () => {
      //@ts-ignore
      logger.log(`Ice Connection State changed ${this.peerConnection?.iceConnectionState}`);
      return undefined;
    };
  }
}

export const sessionDescriptionHandlerFactory = (voip: VoIP) => function(
  session,
  options
): any {

  const iceGatheringTimeout = options?.iceGatheringTimeout !== undefined ? options?.iceGatheringTimeout : 5000;

  const sessionDescriptionHandlerConfiguration: SessionDescriptionHandlerConfiguration = {
    iceGatheringTimeout,
    peerConnectionConfiguration: {
      ...defaultPeerConnectionConfiguration(),
      ...options?.peerConnectionConfiguration
    }
  };

  return new SessionDescriptionHandlerExtended(
    voip,
    session.userAgent.getLogger(),
    mediaStream(),
    sessionDescriptionHandlerConfiguration
  );
};

export default sessionDescriptionHandlerFactory;