import MultichannelSdk from '../../api';
import { VoIPResultState, VoIPResult } from '../VoIP/SelfCheck';
import { ScopedLogger } from '@core/logger';
import Compareable from '../../Comparable';

export class MicTest extends Compareable {
  _api;
  _translate;
  _logger: ScopedLogger;
  inputChannelCount = 6;
  outputChannelCount = 2;
  // Buffer size set to 0 to let Chrome choose based on the platform.
  bufferSize = 0;
  // Turning off echoCancellation constraint enables stereo input.
  constraints;

  collectSeconds = 2.0;
  // At least one LSB 16-bit data (compare is on absolute value).
  silentThreshold = 1.0 / 32767;
  lowVolumeThreshold = -60;
  // Data must be identical within one LSB 16-bit to be identified as mono.
  monoDetectThreshold = 1.0 / 65536;
  // Number of consequtive clipThreshold level samples that indicate clipping.
  clipCountThreshold = 6;
  clipThreshold = 1.0;

  // Populated with audio as a 3-dimensional array:
  //   collectedAudio[channels][buffers][samples]
  collectedAudio = [];
  collectedSampleCount = 0;
  audioContext;
  stream;
  audioSource;
  scriptNode;
  stopCollectingAudio;
  testResult: VoIPResult[];
  _running;
  _resolve;
  _reject;

  constructor(api: MultichannelSdk) {
    super();
    this._api = api;
    this._translate = api.language.translate;
    this._logger = this._api.debug('api.util.micTest');
    this.constraints = this._api.audio.mediaConstraints;
    // this.constraints.audio.optional = [{ echoCancellation: false }];
  }

  async run(): Promise<VoIPResult[]> {
    return new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
      for (var i = 0; i < this.inputChannelCount; ++i) {
        this.collectedAudio[i] = [];
      }
      //@ts-ignore
      var AudioContext = window.AudioContext || window.webkitAudioContext;
      this.audioContext = new AudioContext();

      this.testResult = [];

      // Resuming as per new spec after user interaction.
      this.audioContext
        .resume()
        .then(
          function() {
            this.doGetUserMedia(this.gotStream.bind(this));
          }.bind(this),
        )
        .catch(
          function(error) {
            this.testResult.push({
              state  : VoIPResultState.failed,
              message: `${this._translate('webAudio_run_failure')}: ${error}`,
            });
          }.bind(this),
        );
    });
  }

  gotStream(stream) {
    if (!this.checkAudioTracks(stream)) {
      this._resolve(this.testResult);
      return;
    }
    this.createAudioBuffer(stream);
  }

  checkAudioTracks(stream) {
    this.stream = stream;
    var audioTracks = stream.getAudioTracks();
    if (audioTracks.length < 1) {
      this.testResult.push({
        state  : VoIPResultState.failed,
        message: `${this._translate('no_audio_track_in_returned_stream')}.`,
      });
      return false;
    }
    this.testResult.push({
      state  : VoIPResultState.passed,
      message: `${this._translate('audio_track_created_using_device')}: ${audioTracks[0].label}`,
    });
    return true;
  }

  createAudioBuffer(stream) {
    this.audioSource = this.audioContext.createMediaStreamSource(stream);
    this.scriptNode = this.audioContext.createScriptProcessor(
      this.bufferSize,
      this.inputChannelCount,
      this.outputChannelCount,
    );
    this.audioSource.connect(this.scriptNode);
    this.scriptNode.connect(this.audioContext.destination);
    this.scriptNode.onaudioprocess = this.collectAudio.bind(this);
    this.stopCollectingAudio = this.setTimeoutWithProgressBar(
      this.onStopCollectingAudio.bind(this),
      5000,
    );
  }

  setTimeoutWithProgressBar(timeoutCallback, timeoutMs) {
    // var start = window.performance.now();
    var updateProgressBar = setInterval(function() {
      // var now = window.performance.now();
    }, 100);

    var timeoutTask = function() {
      clearInterval(updateProgressBar);
      timeoutCallback();
    };
    var timer = setTimeout(timeoutTask, timeoutMs);
    var finishProgressBar = function() {
      clearTimeout(timer);
      timeoutTask();
    };
    return finishProgressBar;
  }
  collectAudio(event) {
    // Simple silence detection: check first and last sample of each channel in
    // the buffer. If both are below a threshold, the buffer is considered
    // silent.
    var sampleCount = event.inputBuffer.length;
    var allSilent = true;
    for (var c = 0; c < event.inputBuffer.numberOfChannels; c++) {
      var data = event.inputBuffer.getChannelData(c);
      var first = Math.abs(data[0]);
      var last = Math.abs(data[sampleCount - 1]);
      var newBuffer;
      if (first > this.silentThreshold || last > this.silentThreshold) {
        // Non-silent buffers are copied for analysis. Note that the silent
        // detection will likely cause the stored stream to contain discontinu-
        // ities, but that is ok for our needs here (just looking at levels).
        newBuffer = new Float32Array(sampleCount);
        newBuffer.set(data);
        allSilent = false;
      } else {
        // Silent buffers are not copied, but we store empty buffers so that the
        // analysis doesn't have to care.
        newBuffer = new Float32Array();
      }
      this.collectedAudio[c].push(newBuffer);
    }
    if (!allSilent) {
      this.collectedSampleCount += sampleCount;
      if (this.collectedSampleCount / event.inputBuffer.sampleRate >= this.collectSeconds) {
        this.stopCollectingAudio();
      }
    }
  }

  onStopCollectingAudio() {
    this.stream.getAudioTracks()[0].stop();
    try {
      this.audioSource.disconnect(this.scriptNode);
      this.scriptNode.disconnect(this.audioContext.destination);
    } catch (e) {
      this._logger.trace('audioSource.disconnect: Scriptnode was not connected', { error: e });
    }
    this.analyzeAudio(this.collectedAudio);
    this._resolve(this.testResult);
  }

  analyzeAudio(channels) {
    var activeChannels = [];
    for (var c = 0; c < channels.length; c++) {
      if (this.channelStats(c, channels[c])) {
        activeChannels.push(c);
      }
    }
    if (activeChannels.length === 0) {
      this.testResult.push({
        state  : VoIPResultState.warnings,
        message: this._translate(
          'No_active_input_channels_detected_Microphone_is_most_likely_muted_or_broken_please_check_if_muted_in_the_sound_settings_or_physically_on_the_device_Then_rerun_the_test'
        ),
      });
    } else {
      this.testResult.push({
        state  : VoIPResultState.passed,
        message: `${this._translate('active_audio_input_channels')}: ${activeChannels.length}`,
      });
    }
    if (activeChannels.length === 2) {
      this.detectMono(channels[activeChannels[0]], channels[activeChannels[1]]);
    }
  }

  channelStats(channelNumber, buffers) {
    var maxPeak = 0.0;
    var maxRms = 0.0;
    var clipCount = 0;
    var maxClipCount = 0;
    for (var j = 0; j < buffers.length; j++) {
      var samples = buffers[j];
      if (samples.length > 0) {
        var s = 0;
        var rms = 0.0;
        for (var i = 0; i < samples.length; i++) {
          s = Math.abs(samples[i]);
          maxPeak = Math.max(maxPeak, s);
          rms += s * s;
          if (maxPeak >= this.clipThreshold) {
            clipCount++;
            maxClipCount = Math.max(maxClipCount, clipCount);
          } else {
            clipCount = 0;
          }
        }
        // RMS is calculated over each buffer, meaning the integration time will
        // be different depending on sample rate and buffer size. In practise
        // this should be a small problem.
        rms = Math.sqrt(rms / samples.length);
        maxRms = Math.max(maxRms, rms);
      }
    }

    if (maxPeak > this.silentThreshold) {
      var dBPeak = this.dBFS(maxPeak);
      var dBRms = this.dBFS(maxRms);
      this.testResult.push({
        state  : VoIPResultState.passed,
        message:
          'Channel ' +
          channelNumber +
          ' levels: ' +
          dBPeak.toFixed(1) +
          ' dB (peak), ' +
          dBRms.toFixed(1) +
          ' dB (RMS)',
      });
      if (dBRms < this.lowVolumeThreshold) {
        this.testResult.push({
          state  : VoIPResultState.warnings,
          message: this._translate(
            'Microphone_input_level_is_low_increase_input_volume_or_move_closer_to_the_microphone'
          ),
        });
      }
      if (maxClipCount > this.clipCountThreshold) {
        this.testResult.push({
          state  : VoIPResultState.warnings,
          message: this._translate(
            'Clipping_detected_Microphone_input_level_is_high_Decrease_input_volume_or_move_away_from_the_microphone'
          ),
        });
      }
      return true;
    }
    return false;
  }

  detectMono(buffersL, buffersR) {
    var diffSamples = 0;
    for (var j = 0; j < buffersL.length; j++) {
      var l = buffersL[j];
      var r = buffersR[j];
      if (l.length === r.length) {
        var d = 0.0;
        for (var i = 0; i < l.length; i++) {
          d = Math.abs(l[i] - r[i]);
          if (d > this.monoDetectThreshold) {
            diffSamples++;
          }
        }
      } else {
        diffSamples++;
      }
    }
    if (diffSamples > 0) {
      this.testResult.push({
        state  : VoIPResultState.passed,
        message: `${this._translate('stereo_microphone_detected')}.`
      });
    } else {
      this.testResult.push({
        state  : VoIPResultState.passed,
        message: `${this._translate('mono_microphone_detected')}.`
      });
    }
  }

  dBFS(gain) {
    var dB = (20 * Math.log(gain)) / Math.log(10);
    // Use Math.round to display up to one decimal place.
    return Math.round(dB * 10) / 10;
  }

  getDeviceName(tracks) {
    if (tracks.length === 0) {
      return null;
    }
    return tracks[0].label;
  }

  doGetUserMedia(onSuccess, onFail) {
    // var traceGumEvent = report.traceEventAsync('getusermedia');
    this._logger.trace('getUserMedia');
    const me = this;
    try {
      this._logger.trace({ status: 'pending', constraints: me.constraints });

      // Call into getUserMedia via the polyfill (adapter.js).
      navigator.mediaDevices
        .getUserMedia(me.constraints)
        .then(function(stream) {
          var mic = me.getDeviceName(stream.getAudioTracks());
          me._logger.trace({ status: 'success', microphone: mic });
          onSuccess.apply(this, arguments);
        })
        .catch(function(error) {
          me._logger.trace({ status: 'fail', error: error });

          if (onFail) {
            onFail.apply(this, arguments);
          } else {
            me._logger.error('Failed to get access to local media due to ' + 'error: ' + error);
            me.testResult.push({
              state  : VoIPResultState.failed,
              message: `${me._translate('Failed_to_get_access_to_local_media_due_to_error')}: ${error}`
            });
            me._resolve(me.testResult);
          }
        });
    } catch (e) {
      me._logger.error('getUserMedia failed with exception: ' + e.message);
      this.testResult.push({
        state  : VoIPResultState.failed,
        message: `${this._translate('getUserMedia_failed_with_exception')}: ${e.message}`
      });
      me._resolve(me.testResult);
    }
  }
}
