import {
  UserAgent,
  Registerer,
  RegistererState,
  TransportState,
  Session,
  SessionState,
  Invitation,
  Inviter
} from 'sip.js';

import moment from 'moment';
import DetectRTC from 'detectrtc';
import sessionDescriptionHandlerFactory from './SessionDescriptionHandlerFactory';
import { createProxy } from '../Proxy';
import { fromEvent, Subscription as RxJsSubscription } from 'rxjs';
import MultichannelSdk from '../../api';
import User from '@multichannel/acd/src/api/User';
import SelfCheck from './SelfCheck';
import DtmfTone from './DtmfTone';
import { EVENT_LIST } from '../constants';
import { observable, computed, reaction, action, runInAction } from 'mobx';
import constant from '../constants';
import Compareable from '../../Comparable';
import Events from '@core/events';

/**
 *
 * @property {SIP.UserAgent} _userAgent
 * @property {SIP.Invitation} _session
 * @property {User} _user
 * @property {String} _uri
 * @property {Boolean} _connecting
 * @property {Boolean} _connected
 * @property {Boolean} _registered
 * @property {moment} _timeConnecting
 * @property {moment} _timeConnected
 * @property {moment} _timeDisconnected
 * @property {moment} _timeRegistered
 * @property {moment} _timeUnregistered
 * @property {moment} _timeRegistrationFailed
 */
export default class VoIP extends Compareable {

  @observable private _active;
  private _dtmfToneGenerator: DtmfTone;
  @observable private _connected;
  @observable private _connecting;
  @observable private _hasError;
  @observable private _selfCheck: SelfCheck;
  @observable private _reconnecting: Boolean = false;
  @observable private _reconnectionFailed: Boolean = false;
  @observable private _muted: boolean;
  @observable private _registered;
  @observable private _session: Invitation | Inviter | Session;
  @observable private _sessionState: string;
  @observable private _streamActive;

  private _api: MultichannelSdk;
  private _audio;
  private _isAlerting = false;
  private _isWebRTCSupportedByBrowser;
  private _logger;
  private _proxy;
  private _registerer;
  private _timeConnected;
  private _timeConnecting;
  private _timeDisconnected;
  private _timeRegistered;
  private _timeRegistrationFailed;
  private _timeRinging;
  private _timeUnregistered;
  private _uri;
  private _user;
  private _userAgent;
  private _sipSubdomainRegex;

  private _events: string[] = [];
  private _unloadEvent: RxJsSubscription | null = null;
  private _keyDownSubscription: RxJsSubscription | null = null
  @observable private _testCall: boolean = false;
  @observable testCallError: boolean = false;

  /**
   *
   * @param {VierComApi} api
   */
  constructor(api: MultichannelSdk) {
    super();
    this._proxy = createProxy(this);
    this._api = api;
    this._logger = this._api.debug('api.voip');

    this._userAgent = null;
    this._registerer = null;


    runInAction(() => {
      this._session = null;

      this._connecting = false;
      this._connected = false;
      this._hasError = false;
      this._active = false;
      this._muted = false;
      this._streamActive = false;

      this._registered = false;
      this._reconnecting = false;
      this._reconnectionFailed = false;
      this._sessionState = null;

      this._selfCheck = new SelfCheck(api);

    });

    this._user = null;
    this._uri = null;

    this._isWebRTCSupportedByBrowser = DetectRTC.isWebRTCSupported;

    this._timeConnecting = null;
    this._timeConnected = null;
    this._timeDisconnected = null;
    this._timeRegistered = null;
    this._timeUnregistered = null;
    this._timeRegistrationFailed = null;
    this._timeRinging = null;

    this._sipSubdomainRegex = /(^[^.]*)/;

    this._dtmfToneGenerator = new DtmfTone(api.audio);

    this._api.audio.accessMicrophone();
    this._api.audio.checkDevices();
    Events.on([EVENT_LIST.SDK_VOIP_ERROR], () => {
      this._hasError = true;
    });

    Events.on(EVENT_LIST.SDK_AUDIO_VOLUME_CHANGE_VOICE, (value) => {
      if (this._audio?.volume) {
        //FIX: VOIP Audio fails if volume <= 0
        if (value <= 0) {
          value = 1;
        }
        this._audio.volume = value / 100.0;
        this._logger.trace('Voice Audio Volume changed', value);
      }
    });

    return this._proxy;
  }

  public connect(): void {
    if (!this.connected && !this.connecting) {
      if (this.isWebRTCSupportedByBrowser) {

        const config = this._createUserAgentConfig();
        this._logger.trace('Creating sipjs UA', config);
        this._userAgent = new UserAgent(config);

        this.registerEventHandlers();

        this._userAgent.start().catch(e => {
          this._logger.error('Connection to websocket failed', e);
          Events.trigger([EVENT_LIST.SDK_VOIP_ERROR, 'voip:error'], e);
        });
      } else {
        this._logger.error('Browser does not support WebRTC. VoIP is not connected.');
      }
    } else {
      this._logger.trace('VoIP already connected or still connecting', {
        connected : this.connected,
        connecting: this.connecting
      });
    }
  }

  public async disconnect(): Promise<void> {
    // set Disconnection behaviour
    this._logger.traceCall('disconnect');
    if (this._session) {
      this._session.dispose();
    }
    if (this._userAgent) {
      await this._userAgent.stop();
    }
    this.unregisterEventHandlers();
  }

  public async register(): Promise<boolean> {

    // If there is an old registration, then dispose it
    if (this._registerer) {
      this._logger.trace('A registerer is already there, dispose the old and open a new registration');
      await this._registerer.dispose();
    }

    this._registerer = new Registerer(this._userAgent);

    // Setup registerer state change handler
    this._registerer.stateChange.on(action(newState => {
      switch (newState) {
      case RegistererState.Registered:
        this._registered = true;
        this._timeRegistered = moment();
        Events.trigger([
          EVENT_LIST.SDK_VOIP_REGISTERED,
          'voip:registered',
          EVENT_LIST.SDK_VOIP_STATE_CHANGED,
        ]);
        break;

      case RegistererState.Unregistered:
        this._registered = false;
        this._timeUnregistered = moment();
        Events.trigger([EVENT_LIST.SDK_VOIP_UNREGISTERED, 'voip:unregistered'], {
          response: '',
          cause   : '',
        });

        Events.trigger(EVENT_LIST.SDK_VOIP_STATE_CHANGED);
        break;
      }
    }));

    // Send REGISTER
    try {

      this._logger.trace('ua event: Start registration');
      await this._registerer.register();

      return true;

    } catch (e) {
      this._logger.trace('ua event: registrationFailed');
      this._timeRegistrationFailed = moment();
      this._registered = false;
      this._hasError = true;
      Events.trigger(
        [EVENT_LIST.SDK_VOIP_REGISTRATION_FAILED, 'voip:registrationFailed'],
        {
          response: '',
          cause   : '',
        },
      );

      Events.trigger(EVENT_LIST.SDK_VOIP_STATE_CHANGED);
    }

    return false;
  }

  public acceptSession(): void {
    this._logger.traceCall('acceptSession');

    // Let's make sure the UA onInvitation was called before throwing
    // an error. We set a timeout of 3 seconds.
    const waitForSession = setTimeout(() => {
      if (!this._session) {
        this._logger.error('no voip session to be accepted');
        Events.trigger([EVENT_LIST.SDK_VOIP_NOTIFICATION], 'No voip session to be accepted');
        sessionReactionDisposer();
      }
    }, 3000);

    const sessionReactionDisposer = reaction(
      () => [this._session, this._sessionState],
      (data, reaction) => {
        const session: Invitation = data[0] as Invitation;
        const state: string = data[1] as string;

        if (session) {
          reaction.dispose();
          clearTimeout(waitForSession);

          // When calling _session.accept(), it starts a statechange.
          // calling accept() on "Establishing", "Established" or "Terminated"
          // session leads to an Error.
          if (state === 'Establishing' || state === 'Established' || state === 'Terminated') return;

          const lastSessionId = this._api.storage.get('lastVoipSessionAccepted', 'voip');
          if (session.id !== lastSessionId) {
            this._api.storage.set('lastVoipSessionAccepted', session.id, 'voip');
            session.accept().then(() => {
              this._logger.trace('AcceptSession', session.id);
            }).catch(err => {
              this._logger.error('acceptSession failed', { id: session.id, error: err });
              Events.trigger([EVENT_LIST.SDK_VOIP_NOTIFICATION], 'AcceptSession failed');
              this._api.storage.set('lastVoipSessionAccepted', '', 'voip');
            });
          } else {
            this._logger.trace('Voip Session already accepted, assuming another Tab was faster', session.id);
          }
        }
      },
      { fireImmediately: true }
    );
  }

  get testCallActive () {
    return this._testCall;
  }

  public async makeTestCall() {
    runInAction(() => {
      this._testCall = true;
      this.testCallError = false;
    });
    if (this.session) {
      this._logger.error('Session already Exists can\'t initiate Test Call');
      throw new Error('Session already Exists can\'t initiate Test Call');
    }

    const sipUri = this._api.currentUser ? this._api.currentUser.agentNumberVoip : '';

    const sipParts = sipUri.split('@');
    this._user = sipParts[0] || '';
    let sipUrl = sipParts[1] || '';
    const sipSubdomain = this._sipSubdomainRegex.exec(sipUrl)[0];
    sipUrl = sipUrl.replace(this._sipSubdomainRegex, sipSubdomain.replace('sip', 'webrtc'));

    const config = this._createUserAgentConfig();
    const target = UserAgent.makeURI(`sip:4com_voip_configcall@${sipUrl}`);
    const inviter = new Inviter(this._userAgent, target, config);

    //Create a list of all available States
    const ServedStates = {
      [SessionState.Initial]     : false,
      [SessionState.Establishing]: false,
      [SessionState.Established] : false,
      [SessionState.Terminating] : false,
      [SessionState.Terminated]  : false,
    };

    const ServedConnectionStates = {
      connecting: false,
      connected : false,
      failed    : false,
    };

    inviter.stateChange.addListener((data) => {
      //while process through the call remember each state
      ServedStates[data] = true;
      this._api.debug('api.voip.testcall').trace('Testcall SessionState: ' + data, { data });

      if (data === SessionState.Establishing) {
        //@ts-ignore
        inviter.sessionDescriptionHandler.peerConnection.onconnectionstatechange = (event) => {
          ServedConnectionStates[event.target.connectionState] = true;
          this._api.debug('api.voip.testcall').trace('Testcall ConnectionState: ' + event.target.connectionState, { event: event.target });
        };
      }
      //if we are finished take a look if we made the connection otherwise we have an error
      if (data === SessionState.Terminated) {
        if (!ServedStates[SessionState.Established] || !ServedConnectionStates.connected) {
          if (ServedStates[SessionState.Established]) {
            this._api.debug('api.voip.testcall').warning('[IMPORTANT] Please advice the Customer to Check his Firewall Settings regarding UDP Ports ');
          }
          runInAction(() => {
            this._api.debug('api.voip.testcall').error('Not able to create TestCall');
            this.testCallError = true;
          });
        }
      }
    });

    this.handleInvitation(inviter);
    this._api.debug('api.voip.testcall').trace('Start Invitation of Testcall');
    const invite = await inviter.invite({});

    this._logger.trace(`${this?.session?.id} send Invite`);
    return invite;
  }

  public hangupTestCall() {
    if (!this._testCall) {
      return Promise.reject(new Error('This is no Testcall.'));
    }
    if (!this.session) {
      runInAction(() => {
        this._testCall = false;
      });
      return Promise.reject(new Error('Session does not exist.'));
    }

    const now = moment();
    const duration = moment.duration(now.diff(this._timeRinging));
    this._api.debug('api.voip.testcall').trace('Testcall duration ' + duration.as('seconds') + 's');

    this._api.debug('api.voip.testcall').trace('Hangup Testcall');
    const id = this.session.id;
    this._logger.trace(`[${id}] Terminating...`);
    switch (this.session.state) {
    case SessionState.Initial:
      if (this.session instanceof Inviter) {
        return this.session.cancel().then(() => {
          this._logger.trace(`[${id}] Inviter never sent INVITE (canceled)`);
        });
      } else if (this.session instanceof Invitation) {
        return this.session.reject().then(() => {
          this._logger.trace(`[${id}] Invitation rejected (sent 480)`);
        });
      } else {
        return Promise.reject(new Error('Unknown session type.'));
      }
    case SessionState.Establishing:
      if (this.session instanceof Inviter) {
        return this.session.cancel().then(() => {
          this._logger.trace(`[${id}] Inviter canceled (sent CANCEL)`);
        });
      } else if (this.session instanceof Invitation) {
        return this.session.reject().then(() => {
          this._logger.trace(`[${id}] Invitation rejected (sent 480)`);
        });
      } else {
        return Promise.reject(new Error('Unknown session type.'));
      }
    case SessionState.Established:
      return this.session.bye().then(() => {
        this._logger.trace(`[${id}] Session ended (sent BYE)`);
      });
    case SessionState.Terminating:
      break;
    case SessionState.Terminated:
      break;
    default:
      return Promise.reject(new Error('Unknown state'));
    }

    this._logger.trace(`[${this.session.id}] Terminating in state ${this.session.state}, no action taken`);
    return Promise.resolve();

  }

  public rejectSession(): void {
    this._logger.traceCall('rejectSession');

    if (this._session) {
      try {
        if (this._session instanceof Invitation)
          this._session.reject({});
      } catch (e) {
        //nothing Todo
      }
    }
  }

  private _createUserAgentConfig() {
    const password = this._api.currentUser.voipPassword;
    const sipUri = this._api.currentUser ? this._api.currentUser.agentNumberVoip : '';

    const sipParts = sipUri.split('@');
    this._user = sipParts[0] || '';
    let sipUrl = sipParts[1] || '';
    const sipSubdomain = this._sipSubdomainRegex.exec(sipUrl)[0];
    sipUrl = sipUrl.replace(this._sipSubdomainRegex, sipSubdomain.replace('sip', 'webrtc'));

    const webSocketServer = `wss://${sipUrl}:443`;

    this._uri = UserAgent.makeURI(`sip:${this._user}@${sipUrl}`);

    return {
      authorizationUsername         : this._user,
      authorizationPassword         : password,
      sendInitialProvisionalResponse: true,
      autoStart                     : false,
      uri                           : this._uri,
      allowLegacyNotifications      : true,
      userAgentString               : `Professional Client Web ${constant.VERSION}`,
      transportOptions              : {
        maxReconnectionAttempts: 5,
        keepAliveInterval      : 30,
        server                 : webSocketServer,
        traceSip               : true
      },
      logBuiltinEnabled: false,
      logConfiguration : true,
      logConnector     : (level, category, label, content) => {
        if (this._api.storage.get('debug', 'voip')) {
          switch (level) {
          case 'log':
          case 'debug':
            // eslint-disable-next-line
              console.info(category, content);
            break;
          case 'warn':
            // eslint-disable-next-line
              console.warn(category, content);
            break;
          case 'error':
            // eslint-disable-next-line
              console.error(category, content);
            break;
          default:
            // eslint-disable-next-line
              console.log(category, content);
          }
        }
        this._api.debug('api.voip.sipjs').trace(content, {
          level,
          category,
          label,
        });
      },
      sessionDescriptionHandlerFactory       : sessionDescriptionHandlerFactory(this),
      sessionDescriptionHandlerFactoryOptions: {
        constraints                : this._api.audio.mediaConstraints,
        iceGatheringTimeout        : 500,
        peerConnectionConfiguration: this._api.config?.sdk?.voip?.peerConnectionOptions ? this._api.config.sdk.voip.peerConnectionOptions : {
          iceServers: []
        },
      },
      delegate: {
        onInvite: this.handleInvitation
      }
    };
  }

  public handleKeyEvent(e: KeyboardEvent): void {
    if (
      e.ctrlKey === true ||
      (e.key === '#' && navigator.userAgent.includes('Firefox')) // Special case for the swiss keyboard and firefox ( ctrl + alt + 3 )
    ) {
      let pressedKey: string = e.key || '';
      let availableDtmfChars: string = '0123456789#+*';
      if (availableDtmfChars.includes(pressedKey)) {
        if (pressedKey === '+') {
          pressedKey = '*';
        }

        e.preventDefault();
        e.stopPropagation();

        if (this._session) {
          const user = this._api.currentUser || null;
          if (user && user.status.inConversation) {
            if (this.sendDtmf(pressedKey)) {
              this._logger.trace('voip send dtmf tone', pressedKey);
            } else {
              this._logger.trace('voip send dtmf tone failed', pressedKey);
            }
          }
        }
      }
    }
  }

  @action private _onTransportConnecting(): void {
    this._logger.trace('ua event: connecting');
    this._timeConnecting = moment();
    this._connecting = true;
    this._connected = false;
    this._hasError = false;
    Events.trigger([
      EVENT_LIST.SDK_VOIP_CONNECTING,
      'voip:connecting',
      EVENT_LIST.SDK_VOIP_STATE_CHANGED,
    ]);
  }

  @action private _onTransportConnected(): void {
    this._timeConnected = moment();
    this._logger.trace(
      `ua event: connected after ${this._timeConnected.diff(this._timeConnecting)}`,
    );
    this._connecting = false;
    this._connected = true;
    this._hasError = false;

    Events.trigger([
      EVENT_LIST.SDK_VOIP_CONNECTED,
      'voip:connected',
      EVENT_LIST.SDK_VOIP_STATE_CHANGED,
    ]);

    this.register();
  }

  @action private _onTransportDisconnected(): void {
    this._logger.trace('ua event: disconnected');
    this._timeDisconnected = moment();
    this._connected = false;
    this._connecting = false;
    this._hasError = false;
    Events.trigger([
      EVENT_LIST.SDK_VOIP_DISCONNECTED,
      'voip:disconnected',
      EVENT_LIST.SDK_VOIP_STATE_CHANGED,
    ]);
  }

  /**
   * Registers all required event listeners on the UA.
   *
   */
  @action public registerEventHandlers(): void {
    this._keyDownSubscription = fromEvent(document, 'keydown').subscribe((e: KeyboardEvent) => this.handleKeyEvent(e));
    this._unloadEvent = fromEvent(window, 'unload').subscribe(async () => {
      await this.disconnect();
      if (this._api.currentCall) {
        this._api.currentCall.hangup();
      }
    });

    this._userAgent.transport.stateChange.on(newTransportState => {
      switch (newTransportState) {
      case TransportState.Connecting:
        this._onTransportConnecting();
        break;
      case TransportState.Connected:
        this._onTransportConnected();
        break;
      case TransportState.Disconnected:
        this._onTransportDisconnected();
        break;
      }
    });

    this._events.push(
      Events.on(EVENT_LIST.SDK_ME_STATUS_NEW, async e => {
        if (!e.user) {
          return;
        }
        const me: User = e.user;
        const shouldAlert = me.status.alerting || me.status.outboundAlerting;

        if (!this._isAlerting && shouldAlert && !(await this.autoAcceptInviteIfDesired(me))) {
          Events.trigger(EVENT_LIST.SDK_VOICE_ALERTING_START);
          this._isAlerting = true;
        } else if (this._isAlerting && !shouldAlert) {
          Events.trigger(EVENT_LIST.SDK_VOICE_ALERTING_END);
          this._isAlerting = false;
        }
      }),
    );
  }

  public unregisterEventHandlers(): void {
    this._keyDownSubscription?.unsubscribe();
    this._unloadEvent?.unsubscribe();

    this._events.map(event => {
      Events.off(event);
    });
    this._events = [];
  }

  public registerOnBeforeUnloadEvent(): void {
    this._logger.traceCall('registerOnBeforeUnloadEvent');
    // make sure to check the page should really be left
    window.onbeforeunload = e => {
      const dialogText = this._api.language.translate(
        'Wenn Sie diese Seite verlassen, wird der Anruf beendet! Sind Sie sicher?',
      );
      e.returnValue = dialogText;
      return dialogText;
    };
  }

  public unRegisterOnBeforeUnloadEvent(): void {
    this._logger.traceCall('unregisterOnBeforeUnloadEvent');
    window.onbeforeunload = () => { };
  }

  public sendDtmf(tone: any, options: any = {}, toneTimeout = null): boolean {
    if (this._session) {
      try {
        this._session.sessionDescriptionHandler.sendDtmf(tone, options);
        this._dtmfToneGenerator.play(`${tone}`, toneTimeout);
        return true;
      } catch (e) {
        //Nothing Todo
      }
    }
    return false;
  }

  @action public setActive(): void {
    this._logger.traceCall('setActive');
    this._active = true;
    this.registerOnBeforeUnloadEvent();
  }

  @action public setInactive(): void {
    this._logger.traceCall('setInactive');
    this._active = false;
    this._streamActive = false;
    this.unRegisterOnBeforeUnloadEvent();
  }

  /**
   * Accepts the current session automatically if the user configuration requires it. This method returns true if the
   * call has been accepted automatically or false otherwise. This may be used to determine whether or not it is
   * required to begin alerting (i.e. true means: no need to alert, the user does not have to pick up).
   *
   * @returns {Promise<Boolean>}
   * @private
   */
  private async autoAcceptInviteIfDesired(me: User): Promise<boolean> {
    if (
      (me.status.outboundAlerting && (await me.getAutomaticCallAnsweringOutbound())) ||
      (me.status.alerting && (await me.getAutomaticCallAnsweringInbound()))
    ) {
      await this._api.fanOut(500).then(() => {
        this._logger.trace('Automatically accepting call');
        this.acceptSession();
      });
      return true;
    }
    return false;
  }

  /**
   *
   * @private
   */
  @action
  public handleAccept(): void {
    this._logger.trace('session event: accepted');
    this._streamActive = true;

    const peerConnection = this.sessionPeerConnection;

    if (peerConnection) {
      const remoteStream = new MediaStream();

      peerConnection.getReceivers().forEach(receiver => {
        const track = receiver.track;
        if (track) {
          remoteStream.addTrack(track);
        }
      });
      this._muted = false;

      this._audio = this._api.audio.getAudioElement();
      this._audio.volume = this._api.audio.getVoiceAudioVolume() / 100.0;

      // 18892: If we using Firefox, sinkid doesn't change the Audiostream output
      if (navigator.userAgent.includes('Firefox')) {
        this.attachAudioSource(remoteStream);
      } else {
        // Route the Voiceaudio to the configured Output Device:
        this._audio
          .setSinkId(this._api.audio.getVoiceAudioDevice())
          .then(() => {
            this.attachAudioSource(remoteStream);
          })
          .catch(() => {
            this._logger.trace('unable to set SinkID. Using default device');
            this.attachAudioSource(remoteStream);
          });
      }
    }
  }

  private attachAudioSource(remoteStream: MediaStream): void {
    if (typeof this._audio.srcObject !== 'undefined') {
      this._audio.srcObject = remoteStream;
    } else if (typeof this._audio.mozSrcObject !== 'undefined') {
      this._audio.mozSrcObject = remoteStream;
    } else if (typeof this._audio.src !== 'undefined') {
      // @ts-ignore
      this._audio.src = URL.createObjectURL(remoteStream);
    } else {
      Events.trigger(
        [EVENT_LIST.SDK_VOIP_ERROR, 'voip:error'],
        'attaching stream to element',
      );

      this._api.debug('api.voip').trace('Error attaching stream to element.');
    }
  }

  private handleInvitation = async (invitation) => {
    this._logger.trace('ua event: invite', {
      userAgentState   : this._userAgent?.state || '',
      transportState   : this._userAgent?.transport?.state || '',
      registrationState: this._registerer?.state || '',
      invitationState  : invitation?.state || ''
    });

    /**
     * In some cases the registration is Unregistered . This is just a dirty hack
     * @see {@link https://4com.kanbanize.com/ctrl_board/5/cards/42038/details/}
     */
    if (!this._registerer || this._registerer.state === 'Unregistered') {
      this._logger.warning('Not registered, try to start a new registration');
      if (!await this.register()) {
        this._logger.error('reregistration failed. Reject invitation');
        this.disconnect();
        return;
      }
    }

    runInAction(() => {

      this._timeRinging = moment();
      if (this._session) {
        this._session.dispose();
      }
      this._session = invitation;
      this._sessionState = invitation.state;
    });

    this._session.stateChange.on(action(newState => {
      this._sessionState = newState;
      switch (newState) {
      case SessionState.Established:
        this.handleAccept();
        break;
      case SessionState.Terminated:
        this.setInactive();
        this._session.dispose();
        this._session = null;
        this._sessionState = undefined;
        if (this._testCall) {
          this._testCall = false;
        }
        break;
      default:
        break;
      }
    }));
    this.setActive();

    Events.trigger([EVENT_LIST.SDK_VOIP_INVITE, 'voip:invite'], this._session);
  };

  /**
   * Mutes the Voice and Record tracks to the desired state
   * @param mute
   */
  @action public toggleMute(mute: boolean) {
    if (!this.session) {
      this._logger.trace('No session to toggle mute');
      return;
    }
    const pc = (this.session.sessionDescriptionHandler as any).peerConnection;
    if (pc.getSenders) {
      pc.getSenders().forEach((sender: any) => {
        if (sender.track) {
          sender.track.enabled = !mute;
        }
      });
    } else {
      (pc as any).getLocalStreams().forEach((stream: any) => {
        stream.getAudioTracks().forEach((track: any) => {
          track.enabled = !mute;
        });
        stream.getVideoTracks().forEach((track: any) => {
          track.enabled = !mute;
        });
      });
    }
    this._muted = mute;
  }

  @action public reset(): void {

    this._userAgent = null;
    this._registerer = null;
    this._session = null;

    this._user = null;
    this._uri = null;

    this._active = false;
    this._connected = false;
    this._connecting = false;
    this._hasError = false;
    this._reconnecting = false;
    this._reconnectionFailed = false;
    this._muted = false;
    this._registered = false;
    this._streamActive = false;

    this._timeConnecting = null;
    this._timeConnected = null;
    this._timeDisconnected = null;
    this._timeRegistered = null;
    this._timeUnregistered = null;
    this._timeRegistrationFailed = null;
    this._timeRinging = null;

  }

  get isVoIP(): boolean {
    return !!this._api.currentUser?.isVoIP;
  }

  @computed get active(): boolean {
    return this._active;
  }

  @computed get connected(): boolean {
    return this._connected;
  }

  @computed get hasError(): boolean {
    return this._hasError;
  }

  @computed get connecting(): boolean {
    return this._connecting;
  }

  @computed get reconnecting() {
    return this._reconnecting;
  }

  set reconnecting(value: Boolean) {
    runInAction(() => {
      this._reconnecting = value;
    });
  }

  @computed get reconnectionFailed() {
    return this._reconnectionFailed;
  }

  set reconnectionFailed(value: Boolean) {
    runInAction(() => {
      this._reconnectionFailed = value;
    });
  }

  get isWebRTCSupportedByBrowser(): boolean {
    return this._isWebRTCSupportedByBrowser;
  }

  @computed get registered(): boolean {
    return this._registered;
  }

  get session(): Invitation | Inviter | Session {
    return this._session;
  }

  get sessionPeerConnection(): RTCPeerConnection | null {
    if (this._session && this._session.sessionDescriptionHandler) {
      //@ts-ignore
      return this._session.sessionDescriptionHandler.peerConnection;
    }
    return null;
  }

  @computed get streamActive() {
    return this._streamActive;
  }

  @computed get selfCheck() {
    return this._selfCheck;
  }

  @computed public get muted() {
    return this._muted;
  }

  set debug(value) {
    this._api.storage.set('debug', value, 'voip');
  }
}
