import { createProxy } from '../Proxy';
import MultichannelSdk from '../../api';
import { ScopedLogger } from '@core/logger';
import {
  observable,
  action,
  computed,
} from 'mobx';
import Compareable from '../../Comparable';

/**
 * Base Class for all Conversation Guide Page Questions
 */
export default class Question extends Compareable {
  protected _api: MultichannelSdk;
  protected _proxy;

  id;
  question;
  isMandatory: boolean;
  questionType: QuestionType;
  @observable nextPageId;

  @observable isEvaluated: boolean;
  @observable hasError: boolean;

  @action public evaluate() {
    this.isEvaluated = true;
    this.hasError = false;
  }

  @action reset() {
    this.isEvaluated = false;
    this.hasError = undefined;
  }

  // We have to somehow reuse those already existing Interfaces (in OBM Plugin)
  // interface LvControlValue {
  //   controlid: string;
  //   lvvaluepairsList: LvValuePair[];
  // }

  // interface LvValuePair {
  //   id: string;
  //   text: string;
  //   value: string;
  // }
  @action setValue(_controlValue) {}

  @computed public get isPageSelector() {
    return this.questionType === QuestionType.ComboBox
      || this.questionType === QuestionType.RadioButton;
  }

  constructor(protected _logger: ScopedLogger, data) {
    super();
    this._proxy = createProxy(this);
    this.id = parseInt(data[0], 10);
    this.question = data[1];
    this.isMandatory = data[2] === '1';
    this.questionType = parseInt(data[3], 10);
    return this._proxy;
  }

}

export enum QuestionType {
  InfoText = 0,
  TextBox = 1,
  ComboBox = 2,
  CheckBox = 3,
  RadioButton = 4,
  LinkList = 5,
  Paragraph = 6,
}
