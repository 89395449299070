import 'whatwg-fetch';

export default class RestService {
  public fetch(url): Promise<Response> {
    return fetch(url).then(response => {
      if (response.status === 404) {
        return Promise.reject(`fetch failed: ${url}, because: 404`);
      }

      return Promise.resolve(response);
    });
  }

  public fetchAndRead(url): Promise<string> {
    return this.fetch(url).then(response => {
      return this.readFromResponse(response);
    });
  }

  public readFromResponse(response): string {
    return response.text();
  }
}
