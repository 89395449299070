import {
  observable,
  computed,
  runInAction
} from 'mobx';

export default class Service {

    @observable private _user: any;
    @observable private _customer: any;
    private _name: string;
    private _package: any;

    constructor(service: any) {
      this._user = null;
      this._customer = null;
      this._name = service.serviceName;
      this._package = service;
    }

    set user(user: any) {
      runInAction(() => {
        this._user = user;
      });
    }

    set customer(customer: any) {
      runInAction(() => {
        this._customer = customer;
      });
    }

    @computed get user(): any {
      return this._user || null;
    }

    @computed get customer(): any {
      return this._customer || null;
    }

    get name() {
      return this._name;
    }

    get package() {
      return this._package;
    }

}