import { createProxy } from '../Proxy';
import MultichannelSdk from '../../api';
import { ScopedLogger } from '@core/logger';
import { MicTest } from '../Util/MicTest';
import { observable, action, computed, runInAction } from 'mobx';
import { EVENT_LIST } from '../constants';
import Compareable from '../../Comparable';
import AudioInputTest from './AudioInputTest';
import Events from '@core/events';

export default class SelfCheck extends Compareable {
  _proxy;
  _api: MultichannelSdk;
  _translate;
  _logger: ScopedLogger;
  @observable _categories: VoIPTestCategory[];
  _micTest: MicTest;
  @observable _testResult: VoIPResultState;
  @observable _isRunning: boolean;

  constructor(api: MultichannelSdk) {
    super();
    this._proxy = createProxy(this);
    this._api = api;
    this._logger = this._api.debug('api.voip.selfCheck');
    this._translate = api.language.translate;
    runInAction(()=>{
      this._categories = observable([]);
      this._addTests();
      this._isRunning = false;
      //@ts-ignore
      this._testResult = VoIPResultState.unknown;
    });

    return this._proxy;
  }

  //////////////////////// Public API
  @computed public get categories(): VoIPTestCategory[] {
    return this._categories;
  }

  /**
   * Run all Tests and create the Result Objects
   */
  @action public run() {
    Events.trigger(EVENT_LIST.SDK_VOIP_SELFCHECK_RUN);

    this.reset();
    this._isRunning = true;
    this._micTest = new MicTest(this._api);

    return new Promise(resolve => {
      let catCounter = this._categories.length;

      this._categories.forEach(cat => {
        this._logger.trace(`${catCounter}/${this._categories.length}: ${cat.title}`);
        cat.categoryResult = { state: VoIPResultState.passed };
        let testCounter = cat.tests.length;

        cat.tests.forEach(async voipTest => {
          this._logger.trace(`${testCounter}/${cat.tests.length}: ${voipTest.title}`);
          runInAction(() => voipTest.running = true);
          const results = await voipTest.testFn.call(this);
          voipTest.results = results;
          runInAction(() => voipTest.running = false);
          this._updateGlobalState(voipTest, cat);

          if (--testCounter === 0 && --catCounter === 0) {
            runInAction(()=>{
              this._isRunning = false;
              Events.trigger(EVENT_LIST.SDK_VOIP_SELFCHECK_FINISHED, this.testResult);
            });
            resolve(this.testResult);
          }
        });
      });
    });
  }

  @computed get isRunning() {
    return this._isRunning;
  }

  @action public reset() {
    this._isRunning = false;
    this._categories = observable([]);
    this._testResult = VoIPResultState.unknown;
    this._addTests();
  }

  @computed get testResult() {
    return this._testResult;
  }

  /////////////////////////////// Helper functions

  /**
   * Update Test/Category and global Test Result
   *
   * If a result is local, it will not affect the Category or Global
   * Result. I.e.: Camera Resolution
   * @param cat
   * @param result
   */
  @action private _updateGlobalState(test, cat) {
    test.testResult = { state: VoIPResultState.passed };
    test.results.forEach(res => {
      this._logResult(res, test.title);

      if (res.isLocal) {
        return;
      }

      if (res.state > test.testResult.state) {
        test.testResult.state = res.state;
      }

      if (res.state > cat.categoryResult.state) {
        cat.categoryResult.state = res.state;
      }

      if (res.state > this._testResult) {
        if (res.state === 20) {
          Events.trigger(EVENT_LIST.SDK_VOIP_ERROR, res.message);
        }
        this._testResult = res.state;
      }
    });
  }

  private _logResult(result: VoIPResult, title?) {
    let traceMessage = result.isLocal ? '(local) ' : '';

    switch (result.state) {
    case VoIPResultState.failed: {
      traceMessage += '[FAILED] ';
      break;
    }
    case VoIPResultState.warnings: {
      traceMessage += '[WARNINGS] ';
      break;
    }
    case VoIPResultState.passed: {
      traceMessage += '[PASSED] ';
      break;
    }
    }

    traceMessage += title ? ` [${title}] ` : '';
    traceMessage += result.title ? result.title : '';
    traceMessage += result.message ? result.message : '';

    this._logger.trace(traceMessage);
  }

  @action private _addTest(cat: VoIPTestCategory, test: VoIPTest): SelfCheck {
    if (typeof cat.tests === 'undefined') {
      cat.tests = observable([]);
    }
    cat.tests.push(test);
    return this;
  }

  @action private _addCategory(cat: VoIPTestCategory): VoIPTestCategory {
    if (typeof cat.tests === 'undefined') {
      cat.tests = observable([]);
    }
    this._categories.push(cat);
    return cat;
  }

  ////////////////////////////////////////////////////////////////
  // Test Implementation
  // Add a new Test with _addTest or a new Category bye using _addCategory
  // Tests have a Title and a Callback Function (VoIPTestFunction). The Callback
  // Function must return an Array of VoIPResults, and can be synchrouneus or asynchronues
  ////////////////////////////////////////////////////////////////
  @action private _addTests() {
    // Voip Status
    const voipCat = this._addCategory({ title: this._translate('Voip_Connectivity') });
    this._addTest(voipCat, {
      title : this._translate('Voip_connection_is_established'),
      testFn: this._voidConnectionEstablished
    });
    this._addTest(voipCat, {
      title : this._translate('UDP_connection_is_available'),
      hidden: true,
      testFn: this._udpPossible
    });

    // Audio Connectivity
    const audioCat = this._addCategory({ title: this._translate('Audio_Connectivity') });
    this._addTest(audioCat, {
      title : this._translate('Audio_Context'),
      testFn: this._hasAudioContextGranted,
    });
    this._addTest(audioCat, {
      title : this._translate('Professional_Client_has_permissions_to_access_the_microphone'),
      testFn: this._hasPermissionsForMicrophoneAccess,
    });
    this._addTest(audioCat, {
      title : this._translate('Input_devices_are_available'),
      testFn: this._hasInputDevices,
    });

    this._addTest(audioCat, new AudioInputTest(
      this._translate('Audio_input_can_be_detected'),
      this._canDetectAudio,
    ));
  }
  private _hasAudioContextGranted(): VoIPResult[] {
    const results = [];

    if(this._api.audio.audioContextGranted){
      results.push({
        state: VoIPResultState.passed,

      });
    } else {
      results.push({
        state  : VoIPResultState.failed,
        message: this._translate('Audio_context_not_started')
      });
    }
    return results;
  }

  /////////////////////////// Test Functions ///////////////////
  private _hasPermissionsForMicrophoneAccess(): VoIPResult[] {
    const results = [];

    if (this._api.audio.hasMicrophonePermission) {
      results.push({ state: VoIPResultState.passed });
    } else {
      results.push({
        state  : VoIPResultState.failed,
        title  : this._api.audio.microphonePermission?.name,
        message: this._api.audio.microphonePermission?.message,
      });
    }

    return results;
  }

  private _hasInputDevices(): VoIPResult[] {
    const result = [];
    result.push({
      state:
        this._api.audio.inputDeviceListObservable.length > 0
          ? VoIPResultState.passed
          : VoIPResultState.failed,
    });

    return result;
  }

  private async _canDetectAudio(): Promise<VoIPResult[]> {
    return this._micTest.run();
  }

  private _voidConnectionEstablished(): VoIPResult[] {
    let result = [];

    result.push({
      state: this._api.voip.connected
        ? VoIPResultState.passed
        : VoIPResultState.failed,
    });
    return result;
  }

  private async _udpPossible(): Promise<VoIPResult[]> {
    const config = {
      iceServers: [
        {
          urls: ['stun:stun.l.google.com:19302']
        }
      ]
    };
    return new Promise((resolve) => {
      const pc = new RTCPeerConnection(config);
      const timeout = setTimeout(() => {
        resolve([{
          state  : VoIPResultState.warnings,
          message: `${this._translate('we_failed_to_send_packages_via_udp_or_cant_reach_the_stun_service')}.
            ${this._translate('please_contact_your_local_System_Administrator_or_check_your_Firewall_Settings')}`
        }]);
      }, 5000);

      pc.onicecandidate = function({ candidate }) {
        if (candidate?.type === 'srflx') {
          clearTimeout(timeout);
          resolve([{ state: VoIPResultState.passed }]);
        }
      };

      pc.createOffer({ offerToReceiveAudio: true }).then(description => {
        pc.setLocalDescription(description); //this will trigger the onicecanidate
      });
    });
  }
}

///////////////////////////////////// Interfaces
export interface VoIPTest {
  title: string;
  results?: VoIPResult[];
  testResult?: VoIPResult;
  testFn: VoIPTestFunction;
  running?: boolean;
  showAnimation?: boolean;
  hidden?: boolean;
}

export interface VoIPResult {
  message?: string | ErrorConstructor;
  title?: string | ErrorConstructor;
  state: VoIPResultState;
  // isLocal means, this Result doesn't affect the global Result
  // Example: Camera resolutions
  isLocal?: boolean;
}

export interface VoIPTestFunction {
  (...args): VoIPResult[] | Promise<VoIPResult[]>;
}

export enum VoIPResultState {
  unknown = -1,
  passed = 0,
  warnings = 10,
  failed = 20,
}

export interface VoIPTestCategory {
  title: string;
  tests?: VoIPTest[];
  categoryResult?: VoIPResult;
}
