(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@core/actions"), require("@core/events"), require("@core/logger"), require("@core/messages"), require("@core/storage"), require("mobx"));
	else if(typeof define === 'function' && define.amd)
		define(["@core/actions", "@core/events", "@core/logger", "@core/messages", "@core/storage", "mobx"], factory);
	else if(typeof exports === 'object')
		exports["@multichannel/sdk"] = factory(require("@core/actions"), require("@core/events"), require("@core/logger"), require("@core/messages"), require("@core/storage"), require("mobx"));
	else
		root["@multichannel/sdk"] = factory(root["@core/actions"], root["@core/events"], root["@core/logger"], root["@core/messages"], root["@core/storage"], root["mobx"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__core_actions__, __WEBPACK_EXTERNAL_MODULE__core_events__, __WEBPACK_EXTERNAL_MODULE__core_logger__, __WEBPACK_EXTERNAL_MODULE__core_messages__, __WEBPACK_EXTERNAL_MODULE__core_storage__, __WEBPACK_EXTERNAL_MODULE_mobx__) {
return 