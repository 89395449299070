import SDK, { EVENT_LIST } from './api';

declare global {
  interface Window {
    multichannel: any;
    multichannelJavaScriptSDKConfigUrl: string;
    sdk: SDK;
    System: any
  }
}

let sdk = null;

if (!sdk) {
  SDK.fetchConfig().then((config) => {
    sdk = new SDK(config);
    window.sdk = sdk;
  });
}

export {
  EVENT_LIST
};
export function instance() {
  return sdk;
}
