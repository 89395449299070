import Question from './Question';
import {
  action,
  observable,
  computed,
} from 'mobx';
import { ScopedLogger } from '@core/logger';

export default class QuestionComboBox extends Question {
  options;
  @observable selected: QuestionOption;

  @action public evaluate() {
    this.isEvaluated = true;
    this.hasError = this.isMandatory && typeof this.selected === 'undefined';
    if (this.selected && this.selected.nextPageId > 0) {
      this.nextPageId = this.selected.nextPageId;
    } else {
      this.nextPageId = 0;
    }
  }

  @action setValue(controlValue) {
    this.selected = this.options.find(option => option.id === parseInt(controlValue.lvvaluepairsList[0].id, 10));
  }

  @action reset() {
    super.reset();
    this.selected = undefined;
  }

  @computed public get isPageSelector() {
    return typeof this.options.find(option => option.nextPageId > 0) !== 'undefined';
  }

  constructor(
    protected _logger: ScopedLogger,
    pageData,
  ) {
    super(
      _logger,
      pageData,
    );
    const data = pageData[4];
    this.options = data[1].map(optionData => {
      const label =
        optionData[2].indexOf('') !== -1
          ? optionData[2].substr(0, optionData[2].indexOf(''))
          : optionData[2];
      const option: QuestionOption = {
        id        : parseInt(optionData[0], 10),
        label     : label,
        value     : optionData[1],
        nextPageId: parseInt(optionData[3], 10),
      };
      return option;
    });

    return this._proxy;
  }
}

export interface QuestionOption {
  id: number;
  label: string;
  value: string;
  nextPageId: number;
}
